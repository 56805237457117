/* ===============================================================================
 * recruit styles
 * =============================================================================== */
.page-recruit {
  .container,
  .contents-cover {
    background: #FFF;
  }
  @include min-screen($breakpoint-tablet) {
    .contents-cover {
      padding-top: 50px;
    }
  }
}

.recruit-wrap {
  background: url("/recruit/images/bg.jpg") no-repeat center top;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px 0;

  .recruit-box {
    background: #FFF;
    margin: 0 3%;
    padding: 30px 5%;

    & ~ .recruit-box {
      margin-top: 50px;
    }
  }

  .recruit-summary {
    .caution {
      padding-left: 1.0em;
      text-indent: -1.0em;
    }
    @include cf;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: #d8d8d8 1px solid;

    dd,
    dt {
      padding-left: 5px;
      padding-right: 5px;
    }

    dt {
      border-top: #d8d8d8 1px solid;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 20px;
      background: #f7f7f7;
    }

    dd {
      padding-top: 5px;
    }
    @include min-screen($breakpoint-tablet) {
      padding-bottom: 0;
      border-bottom: none;
      margin-top: 50px;

      dd,
      dt {
        float: left;
        border-top: none;
        padding: 20px 0;
        margin-top: 0;
        text-align: left;
      }

      dt {
        clear: both;
        width: 30%;
        background: transparent;
        padding-left: 5%;
      }

      dd {
        width: calc(100% - 30%);
        border-left: #d8d8d8 1px solid;
        padding-left: 8%;
      }
    }
  }

  .button {
    margin-top: 30px;
  }

  .btn-entry {
    width: 80%;
    margin: 15px auto 0;

    a {
      display: block;
      border: #000 1px solid;
      line-height: 1;
      padding: 15px 30px 15px 20px;
      position: relative;
      text-align: center;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
      }

      &:after {
        background: url("/shared/images/btn_arrow.png") no-repeat right top;
        background-size: auto 100%;
        width: 50px;
        height: 6px;
        right: -15px;
        margin-top: -3px;
      }
    }
  }
  @include min-screen($breakpoint-tablet) {
    padding: 60px 0;
    margin-top: 20px;
    text-align: center;

    .button {
      margin-top: 50px;

      p {
        display: inline-block;
      }

      p:first-child {
        @include font-size(14px);
      }

      .btn-entry {
        width: 220px;
        margin-left: 30px;

        a {
          padding: 18px 30px 18px 20px;

          &:before {
            left: 20px;
          }

          &:after {
            width: 80px;
            height: 11px;
            right: -40px;
            margin-top: -10px;
          }
        }
      }
    }
  }
}