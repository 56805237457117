@charset "utf-8";
/* ===============================================================================
 * 
 * 
 * 
 * 
 * shared styles
 * 
 * 
 * 
 * 
 * =============================================================================== */

[class^="ico-"], [class*=" ico-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="button-"], [class*="button-"] {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic");
  font-weight: bold;
}
@font-face {
  font-family: 'icoset';
  src: url("/shared/css/fonts/icoset.ttf") format("truetype"),url("/shared/css/fonts/icoset.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* import styles */
.invisible {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.view {display: block!important;}
.none {display: none!important;}
.hidden {overflow: hidden!important;}
.fleft {float: left!important;}
.fright {float: right!important;}
.fnone {float: none!important;}

.tleft {text-align: left!important;}
.tcenter {text-align: center!important;}
.tright {text-align: right!important;}

.bold {font-weight: bold!important;}
.normal {font-weight: normal!important;}
.clear {clear:both;}
.vtop {vertical-align: top!important}
.vmdl {vertical-align: middle!important}
.vbtm {vertical-align: bottom!important}
.italic {font-style: italic!important;}

.nowrap {white-space: nowrap!important;}
.wide {
  width: 100%; height: auto; max-width: none!important;
}
.nwide {
  max-width: 100%!important; width: auto!important; height: auto;
}

//font

.fntER {
  font-family:'Raleway', sans-serif!important;;
}
.fntEM {
  font-family:'Montserrat', sans-serif!important;;
}
/* inline-block */
.ib {
  display: inline-block!important;
  vertical-align: top;
}

/* block */
.isSPib {display: inline-block!important;}
.isSPn {display: none!important;}
.isSPb {display: block!important;}
.isPCn {display: block!important;}
.isPCb {display: none!important;}
.isTBn {display: block!important;}
.isTBb {display: none!important;}
.isUTBn {display: block!important;}
.isUTBb {display: none!important;}

@include min-screen($breakpoint-tablet) {
  .isSPib {display: none!important;}
  .isSPn {display: block!important;}
  .isSPb {display: none!important;}
  .isPCn {display: block!important;}
  .isPCb {display: none!important;}
  .isTBn {display: none!important;}
  .isTBb {display: block!important;}
  .isUTBn {display: none!important;}
  .isUTBb {display: block!important;}
}
@include min-screen($breakpoint-pc) {
  .isSPib {display: none!important;}
  .isSPn {display: block!important;}
  .isSPb {display: none!important;}
  .isPCn {display: none!important;}
  .isPCb {display: block!important;}
  .isUTBn {display: none!important;}
  .isUTBb {display: block!important;}
}

.isTablet {
  .isSPib {display: none!important;}
  .isSPn {display: none!important;}
  .isSPb {display: none!important;}
  .isPCn {display: block!important;}
  .isPCb {display: none!important;}
  .isUTBn {display: block!important;}
  .isUTBb {display: none!important;}
}

/*margin*/
.spt0 {margin-top: 0px!important;}
.spt5 {margin-top: 5px!important;}
.spt10 {margin-top: 10px!important;}
.spt15 {margin-top: 15px!important;}
.spt20 {margin-top: 20px!important;}
.spt25 {margin-top: 25px!important;}
.spt30 {margin-top: 30px!important;}
.spt35 {margin-top: 35px!important;}
.spt40 {margin-top: 40px!important;}
.spt45 {margin-top: 45px!important;}
.spt50 {margin-top: 50px!important;}
.spt55 {margin-top: 55px!important;}
.spt60 {margin-top: 60px!important;}


.spb0 {margin-bottom: 0px!important;}
.spb5 {margin-bottom: 5px!important;}
.spb10 {margin-bottom: 10px!important;}
.spb15 {margin-bottom: 15px!important;}
.spb20 {margin-bottom: 20px!important;}
.spb25 {margin-bottom: 25px!important;}
.spb30 {margin-bottom: 30px!important;}
.spb35 {margin-bottom: 35px!important;}
.spb40 {margin-bottom: 40px!important;}
.spb45 {margin-bottom: 45px!important;}
.spb50 {margin-bottom: 50px!important;}
.spb55 {margin-bottom: 55px!important;}
.spb60 {margin-bottom: 60px!important;}


.spl0 {margin-left: 0px!important;}
.spl5 {margin-left: 5px!important;}
.spl10 {margin-left: 10px!important;}
.spl15 {margin-left: 15px!important;}
.spl20 {margin-left: 20px!important;}
.spl25 {margin-left: 25px!important;}
.spl30 {margin-left: 30px!important;}
.spl35 {margin-left: 35px!important;}
.spl40 {margin-left: 40px!important;}
.spl45 {margin-left: 45px!important;}
.spl50 {margin-left: 50px!important;}
.spl55 {margin-left: 55px!important;}
.spl60 {margin-left: 60px!important;}


.spr0 {margin-right: 0px!important;}
.spr5 {margin-right: 5px!important;}
.spr10 {margin-right: 10px!important;}
.spr15 {margin-right: 15px!important;}
.spr20 {margin-right: 20px!important;}
.spr25 {margin-right: 25px!important;}
.spr30 {margin-right: 30px!important;}
.spr35 {margin-right: 35px!important;}
.spr40 {margin-right: 40px!important;}
.spr45 {margin-right: 45px!important;}
.spr50 {margin-right: 50px!important;}
.spr55 {margin-right: 55px!important;}
.spr60 {margin-right: 60px!important;}


.spauto {margin-right:auto!important; margin-left: auto!important;}

/*padding*/
.sppt0 {padding-top: 0!important;}
.sppt5 {padding-top: 5px!important;}
.sppt10 {padding-top: 10px!important;}
.sppt15 {padding-top: 15px!important;}
.sppt20 {padding-top: 20px!important;}
.sppt25 {padding-top: 25px!important;}
.sppt30 {padding-top: 30px!important;}
.sppt35 {padding-top: 35px!important;}
.sppt40 {padding-top: 40px!important;}
.sppt45 {padding-top: 45px!important;}
.sppt50 {padding-top: 50px!important;}
.sppt55 {padding-top: 55px!important;}
.sppt60 {padding-top: 60px!important;}


.sppb0 {padding-bottom: 0!important;}
.sppb5 {padding-bottom: 5px!important;}
.sppb10 {padding-bottom: 10px!important;}
.sppb15 {padding-bottom: 15px!important;}
.sppb20 {padding-bottom: 20px!important;}
.sppb25 {padding-bottom: 25px!important;}
.sppb30 {padding-bottom: 30px!important;}
.sppb35 {padding-bottom: 35px!important;}
.sppb40 {padding-bottom: 40px!important;}
.sppb45 {padding-bottom: 45px!important;}
.sppb50 {padding-bottom: 50px!important;}
.sppb55 {padding-bottom: 55px!important;}
.sppb60 {padding-bottom: 60px!important;}


.sppl0 {padding-left: 0!important;}
.sppl5 {padding-left: 5px!important;}
.sppl10 {padding-left: 10px!important;}
.sppl15 {padding-left: 15px!important;}
.sppl20 {padding-left: 20px!important;}
.sppl25 {padding-left: 25px!important;}
.sppl30 {padding-left: 30px!important;}
.sppl35 {padding-left: 35px!important;}
.sppl40 {padding-left: 40px!important;}
.sppl45 {padding-left: 45px!important;}
.sppl50 {padding-left: 50px!important;}
.sppl55 {padding-left: 55px!important;}
.sppl60 {padding-left: 60px!important;}

.sppr0 {padding-right: 0!important;}
.sppr5 {padding-right: 5px!important;}
.sppr10 {padding-right: 10px!important;}
.sppr15 {padding-right: 15px!important;}
.sppr20 {padding-right: 20px!important;}
.sppr25 {padding-right: 25px!important;}
.sppr30 {padding-right: 30px!important;}
.sppr35 {padding-right: 35px!important;}
.sppr40 {padding-right: 40px!important;}
.sppr45 {padding-right: 45px!important;}
.sppr50 {padding-right: 50px!important;}
.sppr55 {padding-right: 55px!important;}
.sppr60 {padding-right: 60px!important;}
/* width */
.w100p {width: 100%!important;}


