@charset "utf-8";

/* ===============================================================================
 * flying-focus
 * =============================================================================== */

.flying-focus {
  margin: 0;
  top: -100px;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  position: absolute;
  border-radius: 5px;
  z-index: 999999;
  transition: all 0.3s ease-out;

  &.flying-focus_visible {
    display: block;
    visibility: visible;
  }

  &:after {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    bottom: -7px;
    right: -7px;
    border-radius: 2px;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.6), 0 0 3px 1px rgba(0, 0, 0, 0.8) inset, 0 0 1px 3px rgba(255, 255, 255, 1) inset;
  }
}
/* ===============================================================================
 * loading
 * =============================================================================== */

.js-loading {
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: table;
  text-align: center;
  opacity: 1;
  transition: opacity 0.8s ease;

  span {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    transition: letter-spacing 10s, opacity 1s;
    top: 0;
    letter-spacing: 0;
    opacity: 0;
    padding: 0 10%;
  }

  &.start {
    span {
      letter-spacing: 10px;
      opacity: 1;
    }
  }

  &.load {
    opacity: 0;

    span {
      transition: letter-spacing 1.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      letter-spacing: 15px;
    }
  }

  &.out {
    opacity: 0;
  }
  @include min-screen($breakpoint-tablet) {}
}
/* btn */

.btn-more {
  a {
    display: inline-block;
    border: #fff 1px solid;
    line-height: 1;
    @include font-size(13px);
    color: #fff;
    padding: 10px 80px 10px 15px;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 11px;
      left: 70px;
      background: url("#{$path-cmn}btn_arrow.png") no-repeat right top;
      background-size: auto 5px;
      width: 50px;
      height: 5px;
      z-index: 1;
      overflow: hidden;
    }

    span {
      display: inline-block;
      position: relative;
      z-index: 2;
      padding-right: 10px;
    }
  }
  @include min-screen($breakpoint-tablet) {
    a {
      @include font-size(18px);
      color: #fff;
      padding: 15px 180px 15px 15px;

      &:before {
        bottom: 18px;
        left: 90px;
        background: url("#{$path-cmn}btn_arrow.png") no-repeat right top;
        background-size: auto 10px;
        width: 80px;
        height: 10px;
        z-index: 1;
        overflow: hidden;

        .isOtherDevice & {
          transform: translateX(0px);
          transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s 0.15s;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #F2D90D;

        .isOtherDevice & {
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        }
      }

      span {
        display: inline-block;
        position: relative;
        z-index: 2;
        padding-right: 10px;
      }

      &:hover {
        border-color: #F2D90D;

        &:before {
          transform: translateX(10px);
        }

        &:after {
          transform: scaleX(1);
          transform-origin: left;
        }

        span {
          color: #262626;
        }
        //background: #fff;
      }
    }
  }
}
//.btn-more

.btn-more2 {
  a {
    display: inline-block;
    border: #000 1px solid;
    line-height: 1;
    @include font-size(13px);
    color: #000;
    padding: 10px 50px;
    position: relative;
    overflow: hidden;

    &:before {
      content: "\e906";
      @include icoset;
      display: block;
      position: absolute;
      right: 5px;
      top: 50%;
      z-index: 1;
      overflow: hidden;
      color: #000;
      font-size: 24px;
      margin-top: -12px;
    }

    span {
      display: block;
      position: relative;
      z-index: 2;
      padding-right: 10px;
      text-align: center;
      width: 100%;
    }
  }
  @include min-screen($breakpoint-tablet) {
    a {
      @include font-size(18px);
      padding: 15px 50px;
      min-width: 360px;

      &:before {
        right: 30px;
        z-index: 1;
        overflow: hidden;

        .isOtherDevice & {
          transform: translateX(0px);
          transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s 0.15s;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #202020;

        .isOtherDevice & {
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        }
      }

      span {
        display: inline-block;
        position: relative;
        z-index: 2;
        padding-right: 10px;
      }

      &:hover {
        border-color: #202020;

        &:before {
          transform: translateX(10px);
          color: #FFF;
        }

        &:after {
          transform: scaleX(1);
          transform-origin: left;
        }

        span {
          color: #fff;
        }
        //background: #fff;
      }
    }
  }
}
//.btn-more
/* ===============================================================================
 * animation
 * =============================================================================== */
@keyframes noise {
  0%,
  100% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(10px, 10px);
  }

  20% {
    transform: translate(-10px, -10px);
  }

  30% {
    transform: translate(20px, 20px);
  }

  40% {
    transform: translate(-20px, -20px);
  }

  50% {
    transform: translate(40px, 40px);
  }

  60% {
    transform: translate(-30px, -30px);
  }

  70% {
    transform: translate(20px, 20px);
  }

  80% {
    transform: translate(-10px, -10px);
  }

  90% {
    transform: translate(-20px, -20px);
  }
}
@keyframes menuLeft {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/* Icon Drop */
@keyframes hvr-icon-drop {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  40% {
    opacity: 0;
    transform: translateY(30%);
  }
  51% {
    opacity: 0;
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes leftMove {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes leftMoveScale1 {
  0% {
    top: 50%;
    left: -1px;
    height: 1px;
    transform: skew(0, -5deg) translateX(-100%);
  }

  100% {
    top: 50%;
    left: 0;
    height: 1px;
    transform: skew(0, -5deg) translateX(0);
  }
}
@keyframes leftMoveScale2 {
  0% {
    top: 50%;
    height: 1px;
    transform: skew(0, -5deg) translateX(0);
  }

  99% {
    top: -20%;
    height: 140%;
    transform: skew(0, -5deg) translateX(0);
  }

  100% {
    top: 0;
    height: 100%;
    transform: skew(0, 0) translateX(0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes scrollIcon {
  0% {
    top: 4px;
  }

  50% {
    top: 8px;
  }

  100% {
    top: 8px;
  }
}
@keyframes slideTtoB {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
@keyframes slideBtoT {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
@keyframes slideLtoR {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes slideRtoL {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.ttlanim {
  overflow: hidden;
  padding: 0 2%;

  span {
    display: block;
    z-index: 1;
    padding: 0 10px;
    transform: translateY(100%);
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: #F2D90D;
      transform: translateY(100%);
      transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
    }
  }

  &.anim {
    span {
      transform: translateY(0%);

      &:before {
        transform: translateY(-101%);
      }
    }
  }
}
/* ===============================================================================
 * ARIA TAB
 * =============================================================================== */

.tabs__content[aria-hidden="true"] {
  display: none;
}

.container {
  padding-bottom: 80px;
  background: #F4F4F4;

  .page-index & {
    background: #FFF;
  }
  @include min-screen($breakpoint-tablet) {
    padding-bottom: 150px;
  }
}
/* ===============================================================================
 * contact
 * =============================================================================== */

.contact-area {
  margin-top: 40px;
  padding-bottom: 70px;
  background: #EFEFEF;
  @include min-screen($breakpoint-tablet) {
    margin-top: 100px;
    padding-bottom: 130px;
    background: #EFEFEF;
  }
  @include min-screen($breakpoint-pc) {
    padding-bottom: 140px;
  }
}

.contact {
  height: 120px;

  .wrap {
    position: relative;
  }

  &.covers {
    &:after {
      background: url("#{$path-img}top/images/bg_contact.jpg") no-repeat center top;
      background-size: cover;
      opacity: 0;
    }

    &.anim:after {
      transform: translate(0, 0);
      opacity: 1;
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s, opacity 1s 0.5s;
    }

    .ttl {
      text-shadow: #F2D90D 1px 1px 0px, #F2D90D -1px 1px 0px,#F2D90D 1px -1px 0px, #F2D90D -1px -1px 0px, #F2D90D 2px 2px 0px, #F2D90D -2px 2px 0px,#F2D90D 2px -2px 0px, #F2D90D -2px -2px 0px, #F2D90D 3px 3px 0px, #F2D90D -3px 3px 0px,#F2D90D 3px -3px 0px, #F2D90D -3px -3px 0px, #F2D90D 4px 4px 0px, #F2D90D -4px 4px 0px,#F2D90D 4px -4px 0px, #F2D90D -4px -4px 0;
      color: #262626;
    }

    .lead {
      line-height: 1.6;
      color: #262626;
    }

    .covers-back {
      &:before {
        background: #F2D90D;
        transform: translateX(-100%);
      }

      &:after {
        background: #262626;
        transform: translateX(-100%);
      }
    }

    &.anim {
      .btn-more,
      .lead,
      .ttl {
        opacity: 1;
        transform: translateY(0);
      }

      .covers-back {
        &:before {
          left: -1px;
          animation: leftMoveScale1 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.5s forwards ,leftMoveScale2 0.8s cubic-bezier(0.23, 1, 0.32, 1) 1s forwards;
        }

        &:after {
          transform: translateX(-100%);
          animation: leftMove 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
        }
      }
    }
  }

  .txt-area {
    position: absolute;
    top: -30px;
    left: 5%;
    // background: #F2D90D;
    width: 75%;
    height: 220px;
    padding: 60px 20px 20px;

    .back {
      background: #F2D90D;
    }
  }

  .btn-more {
    position: absolute;
    right: -18px;
    bottom: 18px;

    a {
      border-color: #262626;

      &:before {
        left: 115px;
      }
    }

    span {
      color: #262626;
    }
  }
  @include min-screen($breakpoint-tablet) {
    height: 240px;

    .txt-area {
      top: -40px;
      left: 5%;
      width: 70%;
      height: 410px;
      padding: 80px 60px 20px;
    }

    .btn-more {
      right: -80px;
      bottom: 40px;

      a {
        &:before {
          left: 160px;
        }

        &:after {
          background: #262626;
        }

        &:hover {
          span {
            color: #fff;
          }
        }
      }
    }
  }
  @include min-screen($breakpoint-pc) {
    height: 310px;

    .txt-area {
      max-width: 820px;
      height: 490px;
      padding: 160px 0 0 80px;
    }
  }
}
//.contact
/* ===============================================================================
 *
 *
 *
 *
 * common styles mobile first
 *
 *
 *
 *
 * =============================================================================== */

body,
html {
  height: 100%;
}

html {
  overflow-x: hidden;
}

a {
  outline: none;
}
/* covers */

.global-wrapper {
  position: relative;
  @include font-size(16px);
  line-height: 1.8;
  height: 100%;
}

.barba-container,
.barba-inner {
  height: 100%;
}

.isOtherDevice {
  a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.wrap {
  width: 100%;
  padding: 0 5%;
}
@media screen and (min-width: 768px) {
  .global-wrapper {
    //@include font-size(18px);
  }
}
@media screen and (min-width: 1025px) {
  .wrap {
    //max-width: 1240px;
    max-width: 1440px;
    margin: 0 auto;
  }
}

#slideTransition1 {
  background-color: #262626;
}

#slideTransition2 {
  background-color: #F2D90D;
}

.slideTransition {
  position: fixed;
  height: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 100000;
  opacity: 1;
  transition: height 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.transition {
    height: 100%;
  }

  &.remove {
    bottom: none;
    top: 0;
  }
}
/* ===============================================================================
 * header
 * =============================================================================== */

.global-header {
  position: absolute;
  width: 100%;
  z-index: 500;
}

.header {
  .wrap {
    padding: 0;
    position: relative;
  }
  position: relative;
  width: 100%;

  .logo {
    position: absolute;
    top: 15px;
    left: 3%;
    height: 25px;
    color: #FFF;
    z-index: 1;
    vertical-align: top;
    display: inline-block;
    /*     i,
span {
  vertical-align: top;
  display: inline-block;
}

span {
  line-height: 1;
  color: #FFF;
} */
  }
  @include min-screen($breakpoint-tablet) {
    transition: background 0.3s ease;

    .wrap {
      padding: 0 5%;
      height: 120px;
      transition: height 0.5s ease;
    }
    position: fixed;
    width: 100%;

    .logo {
      height: 15px;
      top: 75px;
      //margin-top: -14px;
      transition: top 0.5s ease;

      .is-fixed & {
        top: 15px;
        @include screen (768px,960px) {
          top: 5px;

          img {
            height: 20px;
          }
        }
      }
    }
    @include screen (768px,1086px) {
      .logo {
        height: 15px;
        top: 22px;
        //margin-top: -14px;
      }
    }

    .is-fixed & {
      background: rgba(0,0,0,0.7);

      .wrap {
        height: 70px;
      }
    }
    //.is-fixed
  }
}
//.header

.navigation {
  position: absolute;
  right: 3%;
  width: 100%;

  .is-open & {
    right: 0;
  }

  .button-menu {
    position: absolute;
    top: 10px;
    right: 1.8%;
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .line {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 2px;
      transition: 0.3s;
      background: #fff;

      &::after,
      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        transition: 0.3s;
        background: #fff;
        transform-origin: 50% 50%;
        transition: top 0.3s 0.6s ease, transform 0.2s ease;
      }

      &::before {
        top: -8px;
      }

      &::after {
        top: 8px;
      }
    }
    //line
  }
  //.button-menu
  .gnav-wrap {
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    height: 100vh;
    background: rgba(0,0,0,0);
    transition: background-color 0.5s;
  }

  .gnav-inner {
    position: absolute;
    top: 50%;
    left: 5%;
    width: 90%;
    margin: 0 auto;
    background: #FFF;
    padding: 15px;
    transform: translate(0,-50%) scale(1.05);
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
  }

  .gnav {
    position: relative;
    top: 0;
    transition: top 0.5s ease;

    .is-fixed & {
      top: -66px;
    }

    a {
      display: block;
      padding: 14px 10px;
      @include font-size(16px);
      text-align: center;
      color: #000;

      span {
        display: block;
        &:nth-child(1) {

        }
        &:nth-child(2) {
          @include font-size(12px);
        }
      }
    }

    li ~ li {
      border-top: #ddd 1px solid;
    }
  }

  .is-open & {
    .gnav-wrap {
      display: block;
    }

    .button-menu {
      .line {
        background: transparent;
      }
    }
  }

  .is-anim & {
    .button-menu {
      .line {
        &::after,
        &::before {
          transition: top 0.3s ease, transform 0.3s 0.5s ease;
          top: 0;
        }

        &::before {
          transform: rotate3d(0, 0, 1, 45deg);
        }

        &::after {
          transform: rotate3d(0, 0, 1, -45deg);
        }
      }
    }
  }

  .is-open.is-anim & {
    .gnav-wrap {
      background: rgba(0,0,0,.8);

      .gnav-inner {
        transform: translate(0,-50%) scale(1);
        opacity: 1;
      }
    }
  }

  .utility-nav {
    border-top: #ddd 1px solid;
    padding-top: 10px;
    font-size: 1.4rem;
    letter-spacing: 0.4em;
    transition: top 0.5s ease;

    .is-fixed & {
      top: -100px;
    }
    @include min-screen($breakpoint-tablet) {
      position: absolute;
      right: 0;
      top: -40px;
      width: 50%;
      text-align: right;
      border-top: none;
      padding-top: 0;
      font-size: 1.2rem;
    }
    @include cf;

    li {
      display: inline-block;
      letter-spacing: normal;
      @include min-screen($breakpoint-tablet) {
        margin-right: 15px;
      }

      &:nth-child(2) {
        float: right;
        @include min-screen($breakpoint-tablet) {
          float: none;
        }
      }
    }

    a {
      display: inline-block;
      padding-left: 1.3em;
      position: relative;
      @include min-screen($breakpoint-tablet) {
        color: #FFF;
        padding-left: 1.5em;

        &:hover {
          //text-decoration: underline;
          &:after {
            .isOtherDevice & {
            content: "";
            position: absolute;
            width: calc(100% - 1.5em);
            height: 1px;
            display: block;
            background: #FFF;
            bottom: 0;
            right: 0;
            }
          }
        }
      }

      &:before {
        content: "";
        @include icoset;
        color: #333;
        font-size: 1.6rem;
        left: 0;
        top: 50%;
        margin-top: -9px;
        @include min-screen($breakpoint-tablet) {
          color: #FFF;
          font-size: 1.4rem;
          margin-top: -8px;
        }
      }
    }

    .utility-ac {
      a {
        &:before {
          content: "\e90e";
        }
      }
    }

    .utility-dl {
      a {
        &:before {
          content: "\e90c";
          margin-top: -10px;
          @include min-screen($breakpoint-tablet) {
            margin-top: -9px;
          }
        }
      }
    }
  }
  @include min-screen($breakpoint-tablet) {
    .button-menu {
      display: none;
    }
    //.button-menu
    .gnav-wrap {
      display: block!important;
      position: absolute;
      right: -15px;
      top: 75px;
      width: auto;
      height: auto;
      background: transparent!important;
    }

    .gnav-inner {
      position: static;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      border-bottom: none;
      margin: 0;
      background: transparent;
      padding: 0;
      line-height: 1;
      transform: none;
      opacity: 1;
    }

    .gnav {
      li {
        display: inline-block;
        margin: 0 15px;
        overflow: hidden;
        @include screen (768px,1086px) {
          margin: 0;
        }
      }

      a {
        padding: 12px 10px;

        .is-fixed & {
          padding: 10px;
        }
        @include font-size(13px);
        font-weight: 700;
        color: #fff;
        position: relative;
        letter-spacing: 0.15em;

        
        span {
          display: block;

          &:nth-child(1) {
            letter-spacing: 0.3em;
            font-weight: 600;
          }
          &:nth-child(2) {
            font-weight: 400;
            @include font-size(11px);
            margin-top: 0.7rem;
          }
        }

        &.line:after {
          content: "";
          position: absolute;

          .isOtherDevice & {
            content: "";
            position: absolute;
            background: #FFF;
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            z-index: -1;
          }
        }

        &:focus,
        &:hover {
          &:after {
            .isOtherDevice & {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
      }
      //a
      .gnav-contact {
        a {
          border: none;
          padding-left: 30px;
          padding-right: 30px;
          position: relative;
          background: #E7D125;
          color: #000;
          
          span {
            position: relative;
            z-index: 2;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;

            .isOtherDevice & {
              transform: scaleX(0);
              transform-origin: right;
              transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            }
          }

          &:hover {
            &:before {
              transform: translateX(10px);
              color: #000;
            }
            &:after {
              transform: scaleX(1);
              transform-origin: left;
            }
            span {
              color: #000;
            }
          }
        }
        
      }

      .page-company & .gnav-company,
      .page-contact & .gnav-contact,
      .page-recruit & .gnav-recruit,
      .page-service & .gnav-service,
      .page-works & .gnav-works {
        a {
          cursor: default;

          &:after {
            background: #b19f13;
            width: 120%;
            height: 1px;
            bottom: auto;
            top: 50%;
            left: -10%;
            margin-top: 0;
            transform: translateY(0);
            z-index: 1;
            animation: menuLeft 0.5s cubic-bezier(0.23, 1, 0.32, 1) 1s both;
          }
        }
      }

      .page-contact & .gnav-contact {
        a {
          &:after {
            display: none;
          }
        }
      }

      .page-out.page-company & .gnav-company,
      .page-out.page-contact & .gnav-contact,
      .page-out.page-recruit & .gnav-recruit,
      .page-out.page-service & .gnav-service,
      .page-out.page-works & .gnav-works {
        a {
          &:after {
            transform: translateX(120%);
            animation: none;
          }
        }
      }

      li ~ li {
        border-top: none;
      }
    }
  }
}
//.navigation
//pagetop

.pagetop {
  .wrap {
    position: relative;

    a {
      display: inline-block;
      position: absolute;
      background: #8d8d8d;
      right: 0;
      bottom: 0;
      width: 50px;
      height: 50px;

      img {
        position: absolute;
        width: auto;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -9px;
      }
    }
  }
  @include min-screen($breakpoint-tablet) {
    .wrap {
      a {
        width: 70px;
        height: 70px;

        .isOtherDevice & {
          transition: 0.3s;
        }

        &:hover {
          .isOtherDevice & {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
//pagetop
/* ===============================================================================
 * footer
 * =============================================================================== */

.footer-top {
  //display: none;
  background: #f3f3f3;
  padding: 24px 0;
  @include min-screen($breakpoint-tablet) {
    border-top: #C9C9C9 1px solid;
    padding: 60px 0;
  }
  @include tablet() {
    border-top: #C9C9C9 1px solid;
    padding: 50px 0;
  }
}

.footer-btm {
  background: #222;
  padding: 30px 0;
  @include min-screen($breakpoint-tablet) {
    padding: 60px 0;
  }
  @include tablet() {
    padding: 50px 0;
  }

  .wrap {
    padding: 0 3%;
    
    @include min-screen($breakpoint-tablet) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.footer {
  .add-box {
    position: relative;
    margin-top: -1px;

    & ~ .add-box {
      margin-top: 50px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -3%;
      top: -24px;
      width: 106%;
      height: 1px;
      background: #ccc;
    }

    .ttl {
      @include font-size(15px);
      font-weight: bold;
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 12px;
      color: #000;
      @include min-screen($breakpoint-tablet) {
        padding-bottom: 14px;
        margin-bottom: 30px;
      }

      &:before {
        content: "";
        width: 2.5em;
        height: 3px;
        background: #000;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    p {
      margin-left: 0.5em;
      @include font-size(14px);
      line-height: 1.3;
      margin-top: 0.5em;
      @include min-screen($breakpoint-tablet) {
        line-height: 1.8;
      }
    }

    .ib {
      margin-right: 0.5em;
    }

    .tel {
      text-decoration: underline;
    }

    .maps {
      a {
        display: inline-block;
        text-decoration: underline;
        position: relative;
        padding-left: 16px;

        &:before {
          content: "";
          display: block;
          background: url("#{$path-ui}") no-repeat 0 0;
          position: absolute;
          top: 50%;
          left: 0;
          width: 10px;
          height: 16px;
          margin-top: -8px;
        }

        &:hover {
          //text-decoration: none;
        }
      }
    }
  }
  //.add-box
  .nav {
    display: table;
    word-spacing: -1em;
    margin-top: -5px;
    text-align: center;
    width: 100%;

    li {
      display: inline-block;
      word-spacing: normal;
      width: 33.33333%;
      position: relative;
      @include font-size(13px);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0.15em;
      vertical-align: middle;

      &:before {
        content: "|";
        position: absolute;
        top: 50%;
        left: 0;
        color: #FFF;
        transform: translateY(-50%);
      }

      &:first-child,
      &:nth-child(4) {
        &:before {
          display: none;
        }
      }
    }

    a {
      display: inline-block;
      text-align: center;
      color: #FFF;
      padding: 5px;
      line-height: 1.3;
    }
  }
  //nav
  .logo {
    width: 70%;
    max-width: 300px;
    margin: 15px auto 0;
      @include min-screen($breakpoint-tablet) {
        text-align: right;
      }
    //height: 25px;
    a {
      display: block;
    }

    img {
      height: 26px;
      @include min-screen($breakpoint-tablet) {
        height: auto;
      }
    }
  }

  .copyright {
    color: #FFF;
    @include font-size(10px);
    line-height: 1.2;
    margin-top: 11px;
    text-align: center;
  }

  .japico {
    margin-top: 20px;
    text-align: center;

    @include min-screen($breakpoint-tablet) {
      text-align: right;
    }

    img {
      height: 50px;

      @include min-screen($breakpoint-tablet) {
        height: 100px;
      }
    }
  }

  @include min-screen($breakpoint-tablet) {
    .wrap {
      position: relative;
    }

    .add-box {
      float: left;
      width: 33.3333333%;
      padding-right: 10px;
      margin-top: 0;

      &:last-child {
        padding-right: 0;
      }

      & ~ .add-box {
        margin-top: 0;
      }

      &:before {
        left: 0;
        top: -51px;
        width: 30%;
        height: 3px;
      }

      &:after {
        display: none;
      }

      .ttl {
        @include font-size(16px);
      }

      p {
        margin-left: 0;
      }

      .tel {
        text-decoration: none;
      }

      .maps {
        a {
          .isOtherDevice & {
            transform: translateZ(0);
            backface-visibility: hidden;
            -moz-osx-font-smoothing: grayscale;

            &:before {
              opacity: 1;
              transform: translateZ(0);
            }
          }

          &:active,
          &:focus,
          &:hover {
            &:before {
              .isOtherDevice & {
                animation-name: hvr-icon-drop;
                animation-duration: 0.5s;
                animation-delay: 0.1s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
                animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
              }
            }
          }
        }
      }
    }
    //.add-box
    .nav {
      margin-top: 0;
      text-align: left;
      width: 70%;

      li {
        display: inline-block;
        word-spacing: normal;
        width: auto;

        &:after,
        &:before {
          display: none;
        }
      }

      li + li {
        margin-right: 4%;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    //nav
    .copy {
      width: 30%;
    }

    .logo {
      width: 70%;
      margin: 0 0 0 30%;
      //height: 20px;
      @include tablet() {
        //height: 27px;
      }
    }

    .copyright {
      text-align: right;
      @include tablet() {
         @include font-size(10px);
      }
    }
  }
  @include screen($breakpoint-tablet, 1140px) {
    .add-box {
      .ib,
      p {
        display: block!important;
        @include font-size(12px);
      }

      .fax {
        margin-top: 0.2em;
      }
    }

    .nav {
      li + li {
        margin-right: 2%;
      }
    }
  }
}
//#footer
/* ===============================================================================
 * under parts
 * =============================================================================== */

.main-area {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
  position: relative;

  .wrap {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .title,
  .txt {
    display: inline-block;
    color: #FFF;
  }

  .title {
    @include font-size(40px);
    line-height: 1;
  }

  .txt {
    @include font-size(20px);
  }

  .page-service & {
    background-image: url("#{$path-img}service/images/main.jpg");
  }

  .page-works & {
    background-image: url("#{$path-img}works/images/main.jpg");
  }

  .page-company & {
    background-image: url("#{$path-img}company/images/main.jpg");
  }

  .page-contact & {
    background-image: url("#{$path-img}contact/images/main.jpg");
  }

  .page-recruit & {
    background-image: url("#{$path-img}recruit/images/main.jpg");
  }
  
  .page-privacy & {
    background-image: url("#{$path-img}privacy/images/main.jpg");
  }
  @include min-screen($breakpoint-tablet) {
    height: 578px;

    .wrap {
      padding-top: 80px;
      padding-bottom: 40px;
    }

    .title {
      @include font-size(50px);
    }

    .txt {
      @include font-size(18px);
    }
  }
}

.contents-cover {
  background: #f4f4f4;
  margin: -30px 3% 0;
  padding: 30px 0 15px;
  position: relative;
  z-index: 2;
  @include min-screen($breakpoint-tablet) {
    margin: -40px 3% 0;
    padding: 15px 3%;
    position: relative;
    z-index: 2;
  }
}

.contents-anchor {
  .anchor-nav {
    display: flex;
    flex-wrap: wrap;
    @include justify-content(center);
    @include align-items(center);

    li {
      width: 33.33333333%;
      text-align: center;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        background: #ccc;
        width: 1px;
        height: 20px;
        margin-top: -10px;
      }

      &:last-child {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          background: #ccc;
          width: 1px;
          height: 20px;
          margin-top: -10px;
        }
      }

      a {
        display: block;
        padding: 15px 0;
        margin: 0 -10px;
        position: relative;
      }

      &.active {
        a {
          &:after {
            content: "";
            background: #b19f13!important;
            position: absolute;
            left: 20%;
            bottom: 5px;
            width: 60%;
            height: 1px;
          }
        }
      }
    }
    @include min-screen($breakpoint-tablet) {
      li {
        @include font-size(18px);
        width: 25%;

        &:before {
          height: 30px;
          margin-top: -15px;
        }

        &:last-child {
          &:after {
            content: "";
            height: 30px;
            margin-top: -15px;
          }
        }

        a:focus,
        a:hover {
          &:after {
            content: "";
            position: absolute;
            background: #ccc;
            width: 60%;
            left: 20%;
            bottom: 0;
            height: 2px;
          }
        }

        &.active {
          a {
            &:after {
              width: 60%;
              left: 20%;
              bottom: 0;
              height: 2px;
            }
          }
        }
      }
    }
  }
}

.subttl {
  font-weight: bold;
  @include font-size(20px);
  text-align: center;
  line-height: 1.2;
  padding: 0 20px;

  span {
    display: block;
    @include font-size(16px);
    margin-top: 10px;
  }

  .en {
    @include font-size(16px);
    font-weight: bold;
  }

  .ja {
    @include font-size(12px);
    font-weight: bold;
  }
  @include min-screen($breakpoint-tablet) {
    .en {
      @include font-size(24px);
    }

    .ja {
      @include font-size(18px);
    }
  }
  @include min-screen($breakpoint-tablet) {
     @include font-size(40px);

    span {
      @include font-size(22px);
    }
  }
}