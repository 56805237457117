/* ===============================================================================
 * privacy styles
 * =============================================================================== */
.page-privacy {
  .container {
    background: #FFF;
  }

  .contents-cover {
    padding-bottom: 60px;
    margin-left: 0;
    margin-right: 0;
    padding: 60px 3%;

    @include min-screen($breakpoint-tablet) {
      padding-bottom: 100px;
    }
  }
  
  .privacy-data {
    background: #fff;
    padding: 60px 5% 80px;
    
    .signature {
      p {
        text-indent: 0;
        text-align: right;
      }
    }
    
    h2 {
      margin-bottom: 50px;
      margin-top: 50px;
      
      .ja {
        font-size: 18px!important;
        font-size: 1.8rem!important;
      }
    }
    
    h3 {
      font-weight: bold;
      margin-bottom: 1em;
      margin-top: 2em;
    }
    
    h4 {
      font-weight: bold;
      margin-top: 1em;
    }
    
    ul {
      margin-top: 3em;
      
      ul {
        margin-top: 1em;
      }
    }
    
    p {
      text-indent: 1em;
    }
    
    .contact-box {
      border: 1px solid #ccc;
      padding: 15px;
      margin-top: 5em;
      
      @include min-screen($breakpoint-tablet) {
        padding: 40px;
      }
      
      h3 {
        margin: 0;
      }
      p {
        text-indent: 0;
        margin-top: 0;
      }
      ul {
        margin-top: 0;
      }
      
      a {
        text-decoration: underline;
      }
    }
  }
  
  .toriatsukai {
    ul {
      margin-top: 1em;
      margin-left: 1em;
    }
  }
}