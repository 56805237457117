/* ===============================================================================
 * company styles
 * =============================================================================== */
.page-company {
  .container {
    background: #FFF;
  }

  .contents-cover {
    padding-bottom: 60px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 3%;
    padding-right: 3%;
    @include min-screen($breakpoint-tablet) {
      padding-bottom: 100px;
    }
  }

  .contents-anchor {
    .anchor-nav {
      li {
        width: 30%;
        @include min-screen($breakpoint-tablet) {
          width: 33.333333%;
        }

        &:nth-child(1) {
          width: 70%;
          @include min-screen($breakpoint-tablet) {
            width: 33.333333%;
          }
        }
      }
    }
  }

  .company-data {
    background-repeat: no-repeat;
    background-position: 0 30px;
    background-size: cover;
    background-image: url("#{$path-img}company/images/bg_sp.jpg");
    width: 110%;
    margin: 0 -5%;
    padding: 0 5%;

    .wrap {
      padding-top: 30px;
    }
    @include min-screen($breakpoint-tablet) {
      background-image: url("#{$path-img}company/images/bg.jpg");
      background-position: 0 100px;
      background-size: 100% auto;
      width: 120%;
      margin: 0 -10%;
      padding: 0 10%;

      .wrap {
        padding-top: 100px;
      }
    }
  }

  .company-data_inner {
    background: #FFF;
    width: 106%;
    padding: 20px 3% 50px;
    margin: -30px -3% 0;
    @include min-screen($breakpoint-tablet) {
      margin-top: -60px;
      padding: 80px 10%;
    }
  }

  .company-data_summary {
    @include cf;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: #d8d8d8 1px solid;
    a{
      text-decoration: underline;
    }
    dd,
    dt {
      padding-left: 5px;
      padding-right: 5px;
    }

    dt {
      border-top: #d8d8d8 1px solid;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 20px;
      background: #f7f7f7;
    }

    dd {
      padding-top: 5px;
    }
    @include min-screen($breakpoint-tablet) {
      padding-bottom: 0;
      margin-top: 50px;

      dd,
      dt {
        float: left;
        border-top: #d8d8d8 1px solid;
        padding: 30px 0;
        margin-top: 0;
      }

      dt {
        clear: both;
        width: 25%;
        background: transparent;
        padding-left: 5%;
      }

      dd {
        width: calc(100% - 25%);
      }
    }
  }

  .access-data {
    padding-top: 30px;
    @include min-screen($breakpoint-tablet) {
      padding-top: 100px;
    }
  }

  .access-anchor_wrap {
    width: 100%;
    border-top: #dfdfdf 1px solid;
    border-bottom: #dfdfdf 1px solid;
    box-shadow: 0 3px 0 #f1f1f1;
    padding: 10px 0;
    margin-top: 15px;
    @include min-screen($breakpoint-tablet) {
      margin-top: 50px;
    }
  }

  .access-anchor_nav {
    display: table;
    width: 100%;
    word-spacing: -1em;
    text-align: center;

    li {
      display: inline-block;
      padding: 0 10px;
      word-spacing: normal;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
    @include min-screen($breakpoint-tablet) {
      padding: 15px 0;

      li {
        padding: 0 30px;
      }
    }
  }

  .access {
    .access-data_box {
      padding-top: 20px;
    }

    & ~ .access {
      .access-data_box {
        padding-top: 30px;
        margin-top: 30px;
        border-top: #f1f1f1 5px solid;
      }
    }
    @include min-screen($breakpoint-tablet) {
      .access-data_box {
        padding-top: 50px;
        padding-bottom: 80px;
      }

      & ~ .access {
        .access-data_box {
          padding-top: 80px;
          margin-top: 0;
        }
      }
    }
  }

  .access-data_box {
    .address {
      @include font-size(14px);
      text-align: center;
      margin-top: 10px;

      p ~ p {
        margin-top: 8px;
      }
    }

    .btn-mapviwe {
      width: 80%;
      margin: 15px auto 0;

      a {
        display: block;
        border: #000 1px solid;
        line-height: 1;
        padding: 15px 30px 15px 20px;
        position: relative;
        text-align: center;

        &:after,
        &:before {
          content: "";
          position: absolute;
          top: 50%;
        }

        &:before {
          background: url("/shared/images/ui_sprite.png") no-repeat 0 -26px;
          width: 13px;
          height: 20px;
          margin-top: -10px;
          left: 8px;
        }

        &:after {
          background: url("/shared/images/btn_arrow.png") no-repeat right top;
          background-size: auto 100%;
          width: 50px;
          height: 6px;
          right: -15px;
          margin-top: -3px;
        }
      }
    }
    @include min-screen($breakpoint-tablet) {
      .address {
        @include font-size(18px);
        text-align: center;
        margin-top: 30px;

        p ~ p {
          margin-top: 15px;
        }
      }

      .btn-mapviwe {
        width: 320px;
        margin: 80px auto 0;

        a {
          padding: 18px 30px 18px 20px;

          &:before {
            left: 20px;
          }

          &:after {
            width: 80px;
            height: 11px;
            right: -40px;
            margin-top: -10px;
          }
        }
      }
    }
  }

  .map-canvas {
    margin-top: 10px;
    overflow: hidden;
    height: 400px;
    @include min-screen($breakpoint-tablet) {
      margin-top: 50px;
    }
  }

  .map-hidden {
    @include min-screen($breakpoint-tablet) {
      overflow: hidden;
    }
  }
}
