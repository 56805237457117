/* ===============================================================================
 * index styles
 * =============================================================================== */

@keyframes scrollLine {
  0% {
    transform: translateY(-100%);
  }
  80% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(100%);
  }
}

[data-namespace="page-index"] {
  .container {
    padding-bottom: 0 !important;
  }

  .wrap {
    @include min-screen($breakpoint-tablet) {
      max-width: 1260px;

      .top-ttl_area {
        padding: 0;
      }
    }
  }

  .footer .add-box {
    &:first-child:before {
      display: none;
    }
    @include min-screen($breakpoint-tablet) {
      &:before {
        display: none;
      }
    }
  }

  .fix-nav {
    position: fixed;
    right: 3%;
    top: 50%;
    z-index: 10;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    @include min-screen($breakpoint-tablet) {
      right: 20px;
    }

    li ~ li {
      margin-top: 5px;
    }

    a {
      position: relative;
      display: block;
      width: 6px;
      height: 6px;
      padding: 10px;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #d3d3d3;
      }

      &:active,
      &:hover {
        &:after {
          background: #7d7d7d;
        }
      }
    }

    .active a {
      &:after {
        background: #e7d126!important;
      }
    }
  }

  .split-line {
    position: absolute;
    width: 1px;
    height: 120px;
    top: -60px;
    left: 50%;
    overflow: hidden;
    background: #ddd;

    span {
      display: block;
      width: 100%;
      height: 120px;
      background: #000;
    }
    
    &.scroll-line {
      span {
        animation: scrollLine 1.3s cubic-bezier(.645,.045,.355,1) infinite;
      }
    }
  }

  .top-ttl_area {
    line-height: 1.3;
    color: #000;
    letter-spacing: 0.2em;
    position: relative;
    @include min-screen($breakpoint-tablet) {
      line-height: 1.2;
      padding: 0 5%;
    }
    @include screen(768px,1120px) {
      // padding-bottom: 80px!important;
    }

    .top-bar {
      display: inline-block;
      height: 3px;
      width: 1.2em;
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      transition: width 0.8s cubic-bezier(0.23, 1, 0.32, 1);
      @include min-screen($breakpoint-tablet) {
        height: 6px;
        width: 3.2em;
      }
      &.no-anim {
        width: 0!important;
      }
    }

    .before-txt {
      display: inline-block;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      @include font-size(35px);
      @include min-screen($breakpoint-tablet) {
        letter-spacing: 0.2em;
        @include font-size(60px);
      }

      .thin {
        font-weight: 100;
        @include font-size(24px);
        @include min-screen($breakpoint-tablet) {
          letter-spacing: 0.25em;
          @include font-size(48px);
        }
      }
      .first-txt,
      .second-txt {
        overflow: hidden;
        line-height: 1;
        @include min-screen($breakpoint-tablet) {

        }
        
        &.no-anim {
          width: 0!important;
        }
      }
      .first-txt {
        display: block;
        transition: width 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        
        @include min-screen($breakpoint-tablet) {
          margin-top: 20px;
        }
      }
      .second-txt {
        margin-top: 5px;
        display: block;
        transition: width 1.2s cubic-bezier(0.23, 1, 0.32, 1);
        span {
          font-weight: bold;
        }
        @include min-screen($breakpoint-tablet) {
          display: inline-block;
          margin-top: 13px;
        }
      }
    }
    .after-txt {
      display: block;
      position: relative;
      top: -1em;
      opacity: 1;
      transition: opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1);
      &.no-anim {
        opacity: 0!important;
      }
      
      margin-top: 15px;
      @include min-screen($breakpoint-tablet) {
        display: inline-block;
        margin-top: 0px;
      }
    }

    .btn-more2 {
      @include min-screen($breakpoint-tablet) {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    @include screen(768px,1120px) {
      padding-bottom: 80px;
    }
  }

  .jp {
    display: inline-block;
    @include font-size(12px);
    letter-spacing: 0;
    @include min-screen($breakpoint-tablet) {
       @include font-size(14px);
      margin-left: 0.5em;
    }
  }

  .title {
    position: relative;
    top: 5px;
    @include min-screen($breakpoint-tablet) {
      top: 20px;
    }
    
    &.title-copy {
      position: absolute;
      left: 0;
      z-index: -1;
      opacity: 0.15;
      
      .after-txt {
        display: none;
      }
      
      top: 5px;
      @include min-screen($breakpoint-tablet) {
        top: 20px;
      }
    }
    
    .bar {
      display: inline-block;
      background: #000;
      height: 1px;
      width: 2em;
      @include min-screen($breakpoint-tablet) {
        width: 4em;
      }
    }
  }

  .btn-more2 {
    text-align: right;
    margin-top: 15px;
  }
}
/* ===============================================================================
 * main
 * =============================================================================== */

.main-visual {
  background: url("#{$path-img}top/images/bg_top.jpg") no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;

  .overlay {
    background: rgba(0,0,0,.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .tr-left,
  .tr-right {
    position: absolute;
    z-index: 3;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }

  .tr-left {
    transform: translateY(-100%);
    border-width: 150px 210px 0 0;
    @include min-screen($breakpoint-tablet) {
      border-width: 550px 590px 0 0;
    }
    border-top-color: rgba(0,0,0,.1);
    top: 0;
    left: 0;
  }

  .tr-right {
    transform: translateY(100%);
    border-width: 0 0 150px 210px;
    @include min-screen($breakpoint-tablet) {
      border-width: 0 0 550px 590px;
    }
    border-bottom-color: rgba(0,0,0,.1);
    bottom: 0;
    right: 0;
  }

  .isTouchDevice & .bg-wrap {
    display: none;
  }

  .wrap {
    position: relative;
    height: 100%;
    z-index: 4;
  }
  .main-txt-wrap {
    //mix-blend-mode: overlay;
  }
  
  .main-v-txt {
    position: absolute;
    top: 55%;
    color: #fff;
    
    &.bg {
      color: #fff;
      opacity: 0.3;
    }
    
    padding-right: 3%;
    @include min-screen($breakpoint-tablet) {
      top: 50%;
      width: 75%;
      left: 12.5%;
    }
    @include screen(768px,860px) {
      width: 80%;
      left: 10%;
    }

    transform: translateY(-50%);
    
    .txt1,
    .ttl1,
    .ttl2 {
      overflow: hidden;
      white-space: nowrap;
      
      transition: width 0.8s cubic-bezier(0.23, 1, 0.32, 1);
      width: 0;
      
      &.no-anim {
        width: 0!important;
      }
    }
    .lead {
      opacity: 0;
      transition: opacity 0.6s;
      &.anim.on {
        opacity: 1;
      }
    }
    
    
    .txt1 {
      transition: width 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .ttl1,
    .ttl2 {
      transition: width 1.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .lead {
      transition: opacity 0.6s;
    }
    

    .txt1 {
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 100;
      letter-spacing: 0.2em;
      line-height: 1.3;
      span {
        font-weight: 100;
      }
      @include font-size(18px);
      @include min-screen($breakpoint-tablet) {
         @include font-size(20px);
      }
    }

    .ttl1,
    .ttl2 {
      font-family: 'Montserrat', sans-serif;
      @include font-size(30px);
      line-height: 1.2;

      span {
        font-weight: 100;
      }
      line-height: 1.3;
      margin-top: 0.7em;
      letter-spacing: 0.2em;
      @include min-screen($breakpoint-tablet) {
        letter-spacing: 0.4em;
        margin-top: 0.7em;
        @include font-size(58px);
      }
      @include screen(768px,860px) {
        @include font-size(46px);
      }
    }

    .ttl2 {
      margin-top: 0.4em;
      @include font-size(30px);
      @include min-screen($breakpoint-tablet) {
         @include font-size(62px);
      }
      @include screen(768px,860px) {
        @include font-size(50px);
      }

      span {
        font-weight: bold;
      }

      span.bar {
        display: inline-block;
        padding: 0 0.2em;
        font-weight: 100;
      }
    }

    .lead {
      @include font-size(14px);
      margin-top: 2em;
      line-height: 2.8;
      @include min-screen($breakpoint-tablet) {
         @include font-size(18px);
        letter-spacing: 0.1em;
        margin-top: 1em;
        
      }
    }
    
    &.bg {
      .lead {
        opacity: 0;
      }
    }
    
  }
  /* 
  p {
    position: absolute;
    color: #FFF;
    top: 45%;
    left: 4%;
    line-height: 1;
    @include min-screen($breakpoint-tablet) {
      left: 1.8%;
    }
  }

  .ttl {
    margin-top: -12px;
    @include font-size(24px);
    @include min-screen($breakpoint-tablet) {
       @include font-size(50px);
      margin-top: -25px;
    }
  }

  .lead {
    @include font-size(14px);
    top: 52%;
    margin-top: -11px;
    @include min-screen($breakpoint-tablet) {
       @include font-size(20px);
      top: 52%;
      margin-top: -10px;
    }
  }
 */
  .bg-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    //transform: scale(0.95);
    transform: scale(1);
    //transition: transform 1.5s 0.8s cubic-bezier(0.075, 0.82, 0.165, 1),
    //            opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 1;
  }

  .noise {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    overflow: hidden;
  }

  .noise:after {
    content: '';
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    display: block;
    background: url("#{$path-cmn}noise.png") repeat;
    animation: noise 0.4s 0s steps(1) infinite;
  }

  video {
    display: block;
    position: absolute;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    min-width: 100%;
    min-height: 100%;
    max-width: inherit;
    margin: auto;
    width: auto;
    height: auto;
  }
}
//.main-visual

.covers {
  position: relative;
  z-index: 1;
  //overflow: hidden;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &.anim:after {}

  .btn-more,
  .lead,
  .ttl {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 1.6s, transform 1s cubic-bezier(0.23, 1, 0.32, 1) 1.6s;
    z-index: 11;
  }

  .txt-area {
    position: relative;
    // background: #262626;
    width: 100%;
    color: #FFF;
    z-index: 1;
  }

  .covers-back {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #262626;
      z-index: -1;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #F2D90D;
      z-index: -2;
    }
  }

  .ttl {
    @include font-size(20px);
    line-height: 1;
    text-shadow: #262626 1px 1px 0px, #262626 -1px 1px 0px,#262626 1px -1px 0px, #262626 -1px -1px 0px, #262626 2px 2px 0px, #262626 -2px 2px 0px,#262626 2px -2px 0px, #262626 -2px -2px 0px, #262626 3px 3px 0px, #262626 -3px 3px 0px,#262626 3px -3px 0px, #262626 -3px -3px 0;
    position: relative;

    span {
      display: inline-block;
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 1px;
      background: #685a35;
      width: 2px;
      height: 50px;
      z-index: 1;
      transform: translate(-50%, -50%) skew(-40deg, 0);
      transform-origin: 50% 50%;
      transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
  }

  .lead {
    @include font-size(12px);
    line-height: 1.8;
    margin-top: 15px;
  }
  @include min-screen($breakpoint-tablet) {
    position: relative;

    .wrap {
      z-index: 1;
    }

    .txt-area {
      position: relative;
    }

    .ttl {
      @include font-size(50px);
      /*   #262626 7px 7px 0px, #262626 -7px 7px 0px,#262626 7px -7px 0px, #262626 -7px -7px 0px,
#262626 8px 8px 0px, #262626 -8px 8px 0px,#262626 8px -8px 0px, #262626 -8px -8px 0px */
      text-shadow: #262626 1px 1px 0px, #262626 -1px 1px 0px,#262626 1px -1px 0px, #262626 -1px -1px 0px, #262626 2px 2px 0px, #262626 -2px 2px 0px,#262626 2px -2px 0px, #262626 -2px -2px 0px, #262626 3px 3px 0px, #262626 -3px 3px 0px,#262626 3px -3px 0px, #262626 -3px -3px 0px, #262626 4px 4px 0px, #262626 -4px 4px 0px,#262626 4px -4px 0px, #262626 -4px -4px 0px, #262626 5px 5px 0px, #262626 -5px 5px 0px,#262626 5px -5px 0px, #262626 -5px -5px 0px, #262626 6px 6px 0px, #262626 -6px 6px 0px,#262626 6px -6px 0px, #262626 -6px -6px 0;

      &:after {
        top: 17px;
        left: 6px;
        height: 0;
        width: 3px;
        transition: all 0.3s ease-out;
      }
    }

    .lead {
      @include font-size(18px);
      line-height: 1.8;
    }
  }

  &.anim .ttl:after {
    height: 24px;
  }
  @include min-screen($breakpoint-tablet) {
    &.anim .ttl:after {
      height: 50px;
    }
  }
}
//.covers

[data-namespace="page-index"] {
  .contact-area {
    position: relative;
    z-index: 1;
    margin-top: 0;
    padding: 80px 0;
    @include min-screen($breakpoint-tablet) {
      padding: 120px 0;
    }
    background: url("#{$path-img}top/images/bg_contact2.jpg") no-repeat top center;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .wrap {
      @include min-screen($breakpoint-tablet) {
        padding: 0 5%;
      }
    }

    .contact {
      background: none;
      height: auto;

      &:after {
        display: none;
      }
    }
    color: #FFF;

    .top-ttl_area {

      .title {
        color: #FFF;
      }

      .top-bar,
      .bar {
        background: #FFF;
      }

      & + .txt {
        margin-top: 15px;
        @include font-size(14px);
        text-shadow: #262626 1px 1px 4px, rgba(0,0,0,.5) -1px 1px 4px,rgba(0,0,0,.5) 1px -1px 4px, rgba(0,0,0,.5) -1px -1px 4px, rgba(0,0,0,.5) 2px 2px 4px, rgba(0,0,0,.5) -2px 2px 4px,rgba(0,0,0,.5) 2px -2px 4px, rgba(0,0,0,.5) -2px -2px 4px;
        @include min-screen($breakpoint-tablet) {
          line-height: 2.2;
          margin-top: 35px;
        }
      }
    }

    .contact-wrap {
      @include min-screen($breakpoint-tablet) {
        display: flex;
        align-items: center;
      }
    }

    .cotanct-box {
      margin-top: 30px;
      @include min-screen($breakpoint-tablet) {
        margin-top: 15px;
        &:nth-child(1) {
          width: 60%;
          padding-right: 5%;
        }

        &:nth-child(2) {
          width: 40%;
        }
      }

      .subttl {
        @include font-size(14px);
        font-weight: normal;

        & + .txt {
          @include min-screen($breakpoint-tablet) {}
        }
      }
    }

    .contact-mail,
    .contact-phone {
      padding: 15px;
      @include min-screen($breakpoint-tablet) {
        padding: 35px;
      }
    }

    .contact-phone {
      background: rgba(255,255,255,.9);
      color: #000;

      .subttl {
        @include min-screen($breakpoint-tablet) {}
      }

      .phone {
        font-family: 'Josefin Sans', sans-serif;
        text-align: center;
        @include font-size(24px);
        line-height: 1;
        margin-top: 12px;
        @include min-screen($breakpoint-tablet) {
           @include font-size(42px);
        }

        a,
        span {
          display: inline-block;
          position: relative;
        }

        .tel {
          padding-left: 30px;
          @include min-screen($breakpoint-tablet) {
            padding-left: 45px;
            margin-left: -45px;
          }

          &:before {
            content: "\e913";
            @include icoset;
            position: absolute;
            left: 0;
            top: 60%;
            margin-top: -13px;
            @include font-size(22px);
            @include min-screen($breakpoint-tablet) {
              margin-top: -26px;
              @include font-size(36px);
            }
          }
        }
      }

      .txt {
        font-family: 'Montserrat', sans-serif;
        @include font-size(12px);
        text-align: center;
        line-height: 1;
        margin-top: 8px;
        @include min-screen($breakpoint-tablet) {
          margin-top: 0;
        }
      }
    }

    .contact-mail {
      background: rgba(0,0,0,.7);
      margin-top: 15px;

      .btn-contact {
        margin-top: 12px;

        a {
          display: block;
          width: 100%;
          border: #fff 1px solid;
          @include font-size(13px);
          color: #FFF;
          padding: 10px 50px;
          position: relative;
          overflow: hidden;
          text-align: center;
          font-family: 'Montserrat', sans-serif;
          letter-spacing: 0.2em;
          @include min-screen($breakpoint-tablet) {
             @include font-size(18px);
            padding: 15px 50px;
          }

          &:before {
            content: "\e906";
            @include icoset;
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            z-index: 1;
            overflow: hidden;
            color: #fff;
            @include font-size(20px);
            margin-top: -10px;
            @include min-screen($breakpoint-tablet) {
              right: 30px;
              @include font-size(24px);
              margin-top: -12px;
              overflow: hidden;

              .isOtherDevice & {
                transform: translateX(0px);
                transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s 0.15s;
              }
            }
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #e7d126;

            .isOtherDevice & {
              transform: scaleX(0);
              transform-origin: right;
              transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            }
          }

          span {
            position: relative;
            display: inline-block;
            z-index: 2;
            padding-left: 30px;
            margin-left: -30px;
            @include min-screen($breakpoint-tablet) {
            padding-left: 42px;
            margin-left: -42px;
            }
            &:before {
            content: "\e90b";
            @include icoset;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 1;
            overflow: hidden;
            color: #fff;
            font-size: 22px;
            margin-top: -12px;
            @include min-screen($breakpoint-tablet) {
            font-size: 26px;
            margin-top: -13px;
            }
            }
          }

          &:hover {
            border-color: #e7d126;

            &:before {
              transform: translateX(10px);
              color: #000;
            }

            &:after {
              transform: scaleX(1);
              transform-origin: left;
            }

            span {
              color: #000;
            &:before {
              color: #000;
            }
            }
            //background: #fff;
          }
        }
      }
    }
  }
}

.anchor-wrap {
  @include min-screen($breakpoint-tablet) {
    margin-top: -70px;
    padding-top: 70px;
  }
}
/* ===============================================================================
 * PHILOSOPHY 
 * =============================================================================== */

.philosophy {
  .wrap {
    padding-top: 80px;
    padding-bottom: 80px;
    @include min-screen($breakpoint-tablet) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    // background: url("#{$path-img}top/images/bg_philosophy2.jpg") no-repeat top right;
    background-size: auto 100%;
    
    .isTouchDevice & {
      //background: url("#{$path-img}top/images/bg_philosophy2.jpg") no-repeat top right;
    }
  }
  
  .philosophy-side-movie {
    position: absolute;
    top: 0;
    right: 0;
    .isTouchDevice & {
      display: none;
    }
  }

  .lead {
    @include font-size(16px);
    margin-top: 2em;
    font-weight: bold;
    opacity: 1!important;
    @include min-screen($breakpoint-tablet) {
       @include font-size(22px);
      line-height: 2;
      margin-top: 3em;
    }
  }
}
/* ===============================================================================
 * service
 * =============================================================================== */

.service {
  padding: 80px 0;
  @include min-screen($breakpoint-tablet) {
    padding: 120px 0;
  }
  background: url("#{$path-img}top/images/bg_svc2.jpg") no-repeat 0 0;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* 
  &.anim:after {
    transform: translate(0, 0);
    opacity: 1;
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s, opacity 1s 0.5s;
  }

  &.covers {
    .covers-back {
      &:before {
        transform: translateX(-100%);
      }

      &:after {
        transform: translateX(-100%);
      }
    }
 */
  &.anim {
    /* .btn-more,
      .lead,
      .ttl {
        opacity: 1;
        transform: translateY(0);
      }
       */
    .covers-back {
      /*         &:before {
  left: -1px;
  animation: leftMoveScale1 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.5s forwards ,leftMoveScale2 0.8s cubic-bezier(0.23, 1, 0.32, 1) 1s forwards;
}

&:after {
  transform: translateX(-100%);
  animation: leftMove 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
} */
    }
  }

  .btn-more {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  @include min-screen($breakpoint-tablet) {
    .btn-more {
      position: absolute;
      right: -50px;
      bottom: 40px;
    }
  }

  .svc-info {
    margin-top: 30px;
    padding: 0;
    @include min-screen($breakpoint-tablet) {
      padding: 0 3%;
      display: flex;
      width: 105%;
      padding: 0;
      margin-left: -5%;
      margin-top: 74px;
    }

    .box {
      background: #202020;
      color: #fff;
      padding: 15px;
      @include min-screen($breakpoint-tablet) {
        margin-left: 5%;
        width: 28.3333%;
        padding: 30px;
      }
    }

    .box ~ .box {
      margin-top: 15px;
      @include min-screen($breakpoint-tablet) {
        margin-top: 0;
      }
    }

    .subttl {
      font-family: 'Josefin Sans', sans-serif;
      @include font-size(18px);
      letter-spacing: 0.4em;
      padding: 0;
      @include min-screen($breakpoint-tablet) {
        text-align: left;
      }
    }
    .svc-info_boxwrap {
      align-items: center;
      display: flex;
      margin: 15px 0 5px 0;
      @include min-screen($breakpoint-tablet) {
        display: block;
      }
    }

    .image {
      flex-basis: 50%;
      margin: 0 0 0 3%;
      
      @include min-screen($breakpoint-tablet) {
        width: 100%;
        text-align: center;
        margin: 30px 0 0 0;

        img,svg {
          height: 136px;
          width: auto;
        }
      }
    }

    .txt {
      width: 100%;
      margin-left: 6%;
      @include font-size(12px);
      @include min-screen($breakpoint-tablet) {
        @include font-size(14px);
        margin-top: 30px;
        margin-left: auto;
      }
    }
  }
  
  .svc-subcontent {
    margin-top: 60px;
    
    h3 {
      @include font-size(26px);
      @include min-screen($breakpoint-tablet) {
        @include font-size(18px);
      }
      
      
      span {
        font-weight: bold;
        border-bottom: 2px solid #222;
      }
    }
    p {
      margin-top: 15px;
      @include font-size(16px);
      @include min-screen($breakpoint-tablet) {
        @include font-size(14px);
      }
    }
  }
}
//.servic
/* 
.service-info {
  .service-info_box {
    float: left;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    width: 50%;
    height: 160px;
    color: #FFF;
    position: relative;
  }

  .web {
    background-image: url("#{$path-img}top/images/svc_01.jpg");
  }

  .grp {
    background-image: url("#{$path-img}top/images/svc_02.jpg");
  }

  .video {
    background-image: url("#{$path-img}top/images/svc_03.jpg");
  }

  .etc {
    background-image: url("#{$path-img}top/images/svc_04.jpg");
  }

  figure {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 0;
    padding-left: 10%;
    width: 100%;

    ul {
      li {
        @include font-size(12px);
      }
    }
  }

  figcaption {
    margin-top: 10px;
    @include font-size(18px);
  }
  @include min-screen($breakpoint-tablet) {
    .service-info_box {
      width: 25%;
      height: 260px;
    }

    figure {
      bottom: 20px;
      padding-left: 10%;

      ul {
        li {
          @include font-size(12px);

          & ~ li {
            margin-top: 10px;
          }
        }
      }
    }

    figcaption {
      margin-top: 14px;
      @include font-size(24px);
    }
  }
}
//.service-info */
/* ===============================================================================
 * works
 * =============================================================================== */
[data-namespace="page-index"] {
  .works-area {
    background: url("#{$path-img}top/images/bg_works2.jpg") no-repeat center top;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 80px 0;
    @include min-screen($breakpoint-tablet) {
      padding: 120px 0;
    }
  }
}

.works-area {
  position: relative;
  //margin-top: 30px;
  z-index: 1;
  //overflow: hidden;
  @include min-screen($breakpoint-tablet) {
    //margin-top: 50px;
  }
}

.works {
  //min-height: 200px;
  position: relative;
  /*   .txt-area {
  position: absolute;
  width: 75%;
  right: 0;
  padding: 30px 5%;
  min-height: 220px;
}

.btn-more {
  position: absolute;
  right: 10px;
  top: 40px;

  a {
    padding-right: 60px;
  }
}
 */
  /* &.covers {
    .ttl {
      #262626 5px 5px 0px, #262626 -5px 5px 0px,#262626 5px -5px 0px, #262626 -5px -5px 0px,
     #262626 6px 6px 0px, #262626 -6px 6px 0px,#262626 6px -6px 0px, #262626 -6px -6px 0px,
    #262626 7px 7px 0px, #262626 -7px 7px 0px,#262626 7px -7px 0px, #262626 -7px -7px 0px,
    #262626 8px 8px 0px, #262626 -8px 8px 0px,#262626 8px -8px 0px, #262626 -8px -8px 0px
      text-shadow: #262626 1px 1px 0px, #262626 -1px 1px 0px,#262626 1px -1px 0px, #262626 -1px -1px 0px, #262626 2px 2px 0px, #262626 -2px 2px 0px,#262626 2px -2px 0px, #262626 -2px -2px 0px, #262626 3px 3px 0px, #262626 -3px 3px 0px,#262626 3px -3px 0px, #262626 -3px -3px 0px, #262626 4px 4px 0px, #262626 -4px 4px 0px,#262626 4px -4px 0px, #262626 -4px -4px 0;
    }
  
    &:after {
      background: url("#{$path-img}top/images/bg_works.jpg") no-repeat center top;
      background-size: cover;
      height: 220px;
      @include min-screen($breakpoint-tablet) {
        height: 290px;
      }
      @include min-screen($breakpoint-pc) {
        height: 440px;
      }
      transform: translate(0, -100%);
      opacity: 0;
    }
  
    &.anim:after {
      transform: translate(0, 0);
      opacity: 1;
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s, opacity 1s 0.5s;
    }
  
    .covers-back {
      &:before {
        transform: translateX(-101%);
      }
  
      &:after {
        transform: translateX(-101%);
      }
    }
  
    &.anim {
      .btn-more,
      .lead,
      .ttl {
        opacity: 1;
        transform: translateY(0);
      }
  
      .covers-back {
        &:before {
          left: -1px;
          animation: leftMoveScale1 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.5s forwards ,leftMoveScale2 0.8s cubic-bezier(0.23, 1, 0.32, 1) 1s forwards;
        }
  
        &:after {
          transform: translateX(-100%);
          animation: leftMove 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
        }
      }
    }
  }
  @include min-screen($breakpoint-tablet) {
    min-height: 440px;
  
    &.covers {
      .ttl {
        text-shadow: #262626 1px 1px 0px, #262626 -1px 1px 0px,#262626 1px -1px 0px, #262626 -1px -1px 0px, #262626 2px 2px 0px, #262626 -2px 2px 0px,#262626 2px -2px 0px, #262626 -2px -2px 0px, #262626 3px 3px 0px, #262626 -3px 3px 0px,#262626 3px -3px 0px, #262626 -3px -3px 0px, #262626 4px 4px 0px, #262626 -4px 4px 0px,#262626 4px -4px 0px, #262626 -4px -4px 0px, #262626 5px 5px 0px, #262626 -5px 5px 0px,#262626 5px -5px 0px, #262626 -5px -5px 0px, #262626 6px 6px 0px, #262626 -6px 6px 0px,#262626 6px -6px 0px, #262626 -6px -6px 0px, #262626 7px 7px 0px, #262626 -7px 7px 0px,#262626 7px -7px 0px, #262626 -7px -7px 0px, #262626 8px 8px 0px, #262626 -8px 8px 0px,#262626 8px -8px 0px, #262626 -8px -8px 0;
      }
    }
  
    .txt-area {
      position: absolute;
      width: 65%;
      right: 0;
      padding: 60px 5% 30px;
      min-height: 540px;
    }
  
    .btn-more {
      left: 280px;
      right: 8%;
      top: 120px;
  
      a {
        padding-right: 180px;
      }
    }
  }
  @include min-screen($breakpoint-pc) {
    .txt-area {
      padding-top: 85px;
    }
  
    .btn-more {
      right: auto;
      left: 320px;
      top: 85px;
    }
  } */
}
//.works

.works-info {
  position: relative;
  //background: #FFF;
  width: 100%;
  margin: 0 auto;
  padding: 15px 5% 0;
  z-index: 1;

  .works-list {
    display: table;
    word-spacing: -1em;
    width: 103%;
    margin-left: -3%;

    li {
      display: inline-block;
      word-spacing: normal;
      vertical-align: top;
      width: 47%;
      margin-left: 3%;

      &:nth-child(n+3) {
        margin-top: 10px;
      }

      &:nth-child(n+5) {
        display: none;
      }
    }

    .works-caption {
      display: block;
      position: relative;
      width: 100%;

      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        display: table;
        background: #000;
        opacity: 0.7;
        width: 100%;
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0.1em;

        &:before {
          content: "";
          width: 0;
          display: table-cell;
          height: 100%;
          height: 60px;
        }
      }

      .overlay-wrap {
        display: table-cell;
        vertical-align: middle;
        height: 40%;
        padding: 0 8px;
        line-height: 1;
      }
    }

    p {
      text-align: center;
    }

    .names {
      @include font-size(11px);
      line-height: 1;
    }

    .summary {
      @include font-size(10px);
      margin-top: 8px;
    }
  }
  @include min-screen($breakpoint-tablet) {
    max-width: 1260px;
    padding: 74px 5% 0;

    .works-list {
      display: table;

      li {
        width: 22%;

        &:nth-child(n+3) {
          margin-top: 0;
        }

        &:nth-child(n+5) {
          display: inline-block;
          margin-top: 20px;
        }
      }

      .isOtherDevice & {
        .works-caption {
          display: block;
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .overlay {
            top: 50%;
            left: -1%;
            display: table;
            background: rgba(0,0,0,.7);
            opacity: 0;
            width: 102%;
            height: 100%;
            transition: 0.2s ease-out;
            margin-top: -150px;
            z-index: 0;

            &:before {
              height: 300px;
            }
          }

          .overlay-wrap {
            height: 100%;
            padding: 0 15px;
          }

          &:hover {
            img {
              filter: blur(5px);
            }

            .overlay {
              opacity: 1;
              z-index: 1;
            }
          }
        }
      }

      p {
        text-align: center;
      }

      .names {
        line-height: 1.2;
      }

      .summary {
        margin-top: 8px;
      }
    }
  }
  @include min-screen($breakpoint-pc) {
    .works-list {
      li {
        &:nth-child(n+5) {
          margin-top: 40px;
        }
      }

      .names {
        @include font-size(18px);
      }

      .summary {
        @include font-size(16px);
      }
    }
  }
  @include tablet() {
    .names {
      @include font-size(11px);
    }

    .summary {
      @include font-size(10px);
      margin-top: 2px;
    }
  }
}
//.works-info
/* ===============================================================================
 * recruit
 * =============================================================================== */

.recruit-area {
  padding-bottom: 30px;
  @include min-screen($breakpoint-tablet) {
    padding-bottom: 70px;
  }
}

.recruit {
  margin-top: 20px;
  height: 270px;
  overflow: hidden;
  @include min-screen($breakpoint-tablet) {
    height: 350px;
  }
  @include min-screen($breakpoint-pc) {
    height: 440px;
  }

  &.covers {
    &:after {
      background: url("#{$path-img}top/images/bg_recruit.jpg") no-repeat center top;
      background-size: cover;
      height: 220px;
      @include min-screen($breakpoint-tablet) {
        height: 290px;
      }
      @include min-screen($breakpoint-pc) {
        height: 440px;
      }
      transform: translate(0, -100%);
      opacity: 0;
    }

    &.anim:after {
      transform: translate(0, 0);
      opacity: 1;
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s, opacity 1s 0.5s;
    }

    .covers-back {
      &:before {
        transform: translateX(-100%);
      }

      &:after {
        transform: translateX(-100%);
      }
    }

    &.anim {
      .btn-more,
      .lead,
      .ttl {
        opacity: 1;
        transform: translateY(0);
      }

      .covers-back {
        &:before {
          left: -1px;
          animation: leftMoveScale1 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.5s forwards ,leftMoveScale2 0.8s cubic-bezier(0.23, 1, 0.32, 1) 1s forwards;
        }

        &:after {
          transform: translateX(-100%);
          animation: leftMove 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
        }
      }
    }
  }

  .wrap {
    position: absolute;
    top: 30px;
    right: 5%;
    width: 300px;
    z-index: 1;
  }

  .txt-area {
    height: 220px;
    padding: 30px 20px 20px;
  }

  .btn-more {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  @include min-screen($breakpoint-tablet) {
    margin-top: 80px;

    .wrap {
      position: relative;
      width: auto;
    }

    .txt-area {
      position: absolute;
      top: 20px;
      right: 15px;
      width: 500px;
      height: 300px;
      padding: 80px 30px 0;
    }

    .btn-more {
      position: absolute;
      right: 15px;
      bottom: 50px;
    }
  }
  @include min-screen($breakpoint-pc) {
    height: 500px;

    .txt-area {
      position: absolute;
      top: 50px;
      right: 15px;
      width: 520px;
      height: 430px;
      padding: 95px 50px 50px;
    }

    .btn-more {
      position: absolute;
      right: 35px;
      bottom: 110px;
    }
  }
  @include min-screen(1450px) {
    .txt-area {
      right: -30px;
    }

    .btn-more {
      position: absolute;
      right: -55px;
      bottom: 110px;
    }
  }
}
//.recruit