.page-index {
  .works-view-list {
    margin-top: 0;

    li {
      opacity: 1;
    }
  }
}


/* ===============================================================================
 * works styles
 * =============================================================================== */

  .works-view-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    li {
      width: 50%;
      opacity: 0;
      display: flex;

      &.anim {
        animation: slideUp 1.3s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
      }
      @include font-size(12px);

      &:nth-child(even) {
        padding-left: 1%;
      }

      &:nth-child(odd) {
        padding-right: 1%;
      }

      &:nth-child(n+3) {
        margin-top: 15px;
      }

      .works-caption {
        display: block;
        background: #fff;
        width: 100%;
      }

      .overlay {
        padding: 8px 5%;
      }

      .names {
        line-height: 1.3;
        min-height: 3.2em;

        span {
          padding: 0 3% 0 5%;
          margin-right: 5%;
          display: inline-block;
        }
      }

      .summary {
        padding: 10px 5% 0;
        margin-top: 10px;
        position: relative;
        line-height: 1.3;

        &:before {
          content: "";
          width: 15px;
          height: 1px;
          background: #000;
          position: absolute;
          top: 0;
          left: 5%;
        }
      }
    }
  }
  @include min-screen($breakpoint-tablet) {
    .works-view-list {
      margin-top: 60px;
      padding-bottom: 40px;

      li {
        overflow: hidden;
        width: 25%;
        @include font-size(16px);

        &:nth-child(even) {
          padding-left: 0;
        }

        &:nth-child(odd) {
          padding-right: 0;
        }
        @include tablet() {
           @include font-size(14px);
        }

        &:nth-child(n+3) {
          margin-top: 0;
        }

        &:nth-child(n+5) {
          margin-top: 15px;
        }

        a {
          backface-visibility: hidden;
          font-smoothing: antialiased;
          transition: box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        }

        img {
          backface-visibility: hidden;
          transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
        }

        &:focus,
        &:hover {
          z-index: 1;
          a {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);

            img {
              transform: scale(1.05);
            }
          }
        }

        .overlay {
          overflow: hidden;
          padding: 15px 0;
          position: relative;
          z-index: 1;
          background: #fff;
          &:after {
            content: "";
            position: absolute;
            top: 5%;
            left: 0;
            background: #ebebeb;
            width: 1px;
            height: 90%;
          }
        }

        &:nth-child(4n+1) {
          .overlay {
            &:after {
              display: none;
            }
          }
        }

        .names {
          position: relative;
          line-height: 1.6;
          min-height: 3.2em;

          span {
            position: relative;
            z-index: 1;
            display: inline-block;
            padding: 0 3% 0 8%;
            margin-right: 8%;

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background: #F2D90D;
              width: 0;
              height: 100%;
              z-index: -1;
              transition: width 0.2s cubic-bezier(0.23, 1, 0.32, 1);
            }
          }
        }

        &:focus,
        &:hover {
          .names {
            span {
              &:after {
                width: 100%;
              }
            }
          }
        }

        .summary {
          padding: 15px 8% 0;
          margin-top: 15px;
          line-height: 1.3;
          @include font-size(12px);
          &:before {
            width: 30px;
            left: 8%;
          }
        }
      }
    }
  }

  .isTablet {
    .works-view-list {
      li {
        @include font-size(13px);
      }
    }
  }

/* ===============================================================================
 * works modal
 * =============================================================================== */
body {
  .barba-inner,
  .global-header {
    transition: blur 0.5s;
  }

  &.blur {
    .barba-inner,
    .global-header {
      filter: blur(5px);
    }
  }
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 505;
}

.modal-cover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 510;
  @include tablet() {
    height: auto;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}

.modal-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transform: scale(0.95);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s cubic-bezier(0.23, 1, 0.32, 1);

  .open & {
    opacity: 1;
    transform: scale(1);
  }

  &.wrap {
    padding: 0;
    @include min-screen($breakpoint-tablet) {
      padding: 0 100px;
    }
  }

  .button-close {
    position: absolute;
    display: block;
    top: 8%;
    right: 7%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 2;

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
    }

    &::before {
      top: 20px;
      transform: rotate3d(0, 0, 1, 45deg);
    }

    &::after {
      top: 20px;
      transform: rotate3d(0, 0, 1, -45deg);
    }
    @include min-screen($breakpoint-tablet) {
      top: 8%;
      right: 5%;
      width: 80px;
      height: 80px;

      &::before {
        top: 40px;
      }

      &::after {
        top: 40px;
      }
    }
  }
}

.modal-cont {
  width: 100%;
  color: #fff;
  @include min-screen($breakpoint-tablet) {
    width: 100%;
    color: #fff;
  }
}

.modal-detail {
  min-height: 100px;
  margin: 50px auto 10px;
  padding: 0 50px;
  position: relative;
  z-index: 1;
  @include min-screen($breakpoint-tablet) {
    min-height: 0;
    max-width: 990px;
    padding: 0 100px;
  }
  @include min-screen($breakpoint-pc) {
    padding: 0 50px;
  }

  .works-name {
    @include font-size(16px);
  }

  .works-name-sub {
    @include font-size(12px);
    margin-top: 6px;
    line-height: 1.3;
  }

  .works-url {
    @include font-size(10px);
    margin-top: 26px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -15px;
      left: 0;
      height: 1px;
      width: 30px;
      background: #CEB46E;
    }
  }

  a {
    color: #FFF;
  }
  @include min-screen($breakpoint-tablet) {
    .works-name {
      @include font-size(30px);
    }

    .works-name-sub {
      @include font-size(18px);
      margin-top: 6px;
    }

    .works-url {
      @include font-size(14px);
      margin-top: 50px;

      &:before {
        top: -25px;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @include tablet() {
    .works-name {
      @include font-size(24px);
    }
  }
}

.modal-slide-cover {
  margin: 50px auto 10px;
  padding: 0 50px;
  position: relative;

  .modal-slide-inner {
    background: #FFF;
    padding: 10px 10px;
  }
  @include min-screen($breakpoint-tablet) {
    max-width: 990px;
    position: relative;
    padding: 0 100px;

    .modal-slide-inner {
      background: #FFF;
      padding: 50px 80px;
    }
  }
  @include min-screen($breakpoint-pc) {
    padding: 0 50px;
  .modal-slide-inner {
    background: #FFF;
    padding: 50px 80px;
  }
  }
}

.modal-outer {
  position: relative;
  height: 100%;

  .slick-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    width: 26px;
    height: 48px;
    position: absolute;
    top: 50%;
    margin-top: -24px;
    overflow: hidden;
    text-indent: -9999px;
    content: "";
    background-repeat: no-repeat;
    background-image: url("#{$path-ui}");
    background-size: 60px auto;
    z-index: 2;

    &.slick-disabled {
      opacity: 0.3;
    }
  }

  .slick-prev {
    left: 10px;
    background-position: 0 -70px;

    &:before {}
  }

  .slick-next {
    right: 10px;
    background-position: 0 -124px;

    &:before {}
  }
  @include min-screen($breakpoint-tablet) {
    .slick-arrow {
      width: 43px;
      height: 78px;
      background-size: 100px auto;
    }

    .slick-prev {
      left: 20px;
      background-position: 0 -118px;

      &:before {}
    }

    .slick-next {
      right: 20px;
      background-position: 0 -206px;

      &:before {}
    }
  }
  @include min-screen($breakpoint-pc) {
    .slick-prev {
      left: 40px;
    }

    .slick-next {
      right: 40px;
    }
  }
}

.modal-slider {
  position: relative;
  box-shadow: 0px 0px 20px 0px #999;

  .modal-slide-box {
    outline: none;
  }
  @include min-screen($breakpoint-tablet) {
    .modal-slide-box {
      outline: none;
    }
  }
}