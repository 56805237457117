@charset "utf-8";
/* ===============================================================================
 * 
 * 
 * 
 * 
 * reset styles
 * 
 * 
 * 
 * 
 * =============================================================================== */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-style:normal;
  //font-weight:normal;
  vertical-align: baseline;
  background: transparent;
}
*,
*:before,
*:after{
  box-sizing: border-box;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {display: block;}

h1,h2,h3,h4,h5,h6 {font-size: 100%; font-weight: normal;}

ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before,
blockquote:after,
q:before,
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

table {border-collapse: collapse; border-spacing: 0;font-size: inherit; font: 100%;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle;}

small {font-size: 100%;}

strong {font-weight: bold;}

img {vertical-align: top;}


/* hand cursor on clickable elements */
label,
input[type=button],
input[type=submit],
input[type=file],
button,
select,
option {cursor: pointer;}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0;}

/* make buttons play nice in IE */
button,
input[type=button] {width: auto; overflow: visible;}

/* respons */
img {
  height:auto;
  vertical-align: top;
  width:100%;
  max-width:none;
  @include min-screen($breakpoint-tablet) {
    max-width:100%;
    width:auto;
  }
  @include min-screen($breakpoint-pc) {
    
  }
}

/* ALL default */
html {
  font-size: 62.5%;
  &.spmenu {
    body {
      overflow: hidden;
    }
  }
}
body {
  font-family: "Yu Gothic", YuGothic, Meiryo,'メイリオ', sans-serif;
  font-size: 1.6em;
  line-height:1;
  text-size-adjust:100%;
  color: #333;
  input,button,select {
    font-family: "Yu Gothic", YuGothic, Meiryo,'メイリオ', sans-serif;
  }

  @include min-screen($breakpoint-pc) {
    font-size: 1.8em;
  }
  .isIE11 &,
  .isEdge & {
    font-family: "游ゴシック", YuGothic, Meiryo,'メイリオ', sans-serif;
    input,button,select {
      font-family: "游ゴシック", YuGothic, Meiryo,'メイリオ', sans-serif;
    }
  }
}

#wrapper {
  font-size: 16px;
  font-size: 1.6rem;

  @include min-screen($breakpoint-pc) {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

/* ClearFix */
.cf,.wrap {
   zoom: 1;
  &:after {
    content:"";
    display:block;
    clear:both;
  }
}
a:link, a:visited, a:active {
  color: #000;
  text-decoration: none;
  transition: color 0.5s;
  .isOtherDevice & {
    backface-visibility: hidden;
  }
}

