@charset "utf-8";
@import "core";

@import "reset";
@import "shared";
@import "common";

@import "parts-slick";

@import "page-index";
@import "page-service";
@import "page-works";
@import "page-company";
@import "page-recruit";
@import "page-contact";
@import "page-privacy";

.telework-contact {
  margin-bottom: 5rem;

  p {
    border: 1px solid #666;
    //border: 3px solid #F2D90D;
    padding: 15px;
    //background:#F2D90D;
    font-size: 1.4rem;

    @include min-screen($breakpoint-tablet) {
      font-size: 1.6rem;
      padding: 20px;
      text-align: center;
    }

    a {
      text-decoration: underline;
    }
  }
}
