/* ===============================================================================
 * slick style
 * =============================================================================== */
.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block; }

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden; }

.slick-slider {
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-list, .slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
  height: 100%; }

.slick-track {
  top: 0;
  left: 0; }

.slick-track:after, .slick-track:before {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir=rtl] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto; }

.slick-arrow.slick-hidden {
  display: none; }