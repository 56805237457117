@charset "utf-8";
// ============================================================
//  【SETTING】
// ============================================================
// HTML5の場合は値を「false」から「true」にする
$use_html5: true;

// 対応ブラウザ選択　値を「false」or「true」にする
$support-ie6: false;
$support-ie7: false;
$support-ie8: false;
$support-ie9: true;
$support-mozilla: true;
$support-webkit : true;
$support-opera : true;

// CSS3利用時の対応ブラウザ選択　値を「false」or「true」にする
$use-prefix-webkit: true;
$use-prefix-moz   : true;
$use-prefix-ms    : true;
$use-prefix-o     : true;
$use-prefix-svg   : false;  //IE9
$use-prefix-pie   : false; // IE6-8対応JS必須
$pie-path :"/common/css/PIE.htc"; //Pie.htc 設置場所

// 付与するベンダープレフィックス（簡易版用）
$set_prefix: -webkit-, -moz-, -ms-, -o-, '';

// # IEでフィルターを使うかどうか
$use-ie-filter    : true;
$use-ie-expression: true;


// ------------------- hack -------------------
$slash: "/"; // #{$slash}
$ie6to9: "\0/"; // #{$hack-ie6to9};  IE6,7,8,9 (10も??)
// IE6                          * html
// IE7                          *:first-child + html
// IE6,7                        selector{*color: red;}
// IE7,8,9と他のモダンブラウザ  selector{color/**/: red;}
// IE7,8,9                      selector{color/*\**/: green\9;}
// IE6,7,8,9 (10も??)           selector{color:red\9}

// ------------------- スマホ用メモ -------------------
// @media all and (orientation:portrait)  // 縦
// @media all and (orientation:landscape) // 横


// ============================================================
//  画像パス #{$path-ui}
// ============================================================
$path-cmn: "/shared/images/";
$path-ui: "/shared/images/ui_sprite.png";
$path-img: "/";
$set_prefix: -webkit-, -moz-, -ms-, -o-, '';


// ============================================================
//  【BASE】基本的なMixin
// ============================================================

// 例：@include radius(5);
@mixin cf {
  *zoom:1;
  &:after {
    content:"";
    display:block;
    clear:both;
  }
}
@mixin border_radius($br_value: 3px) {
  @each $prefix in $set_prefix {
      #{$prefix}border-radius: $br_value;
  }
}
@mixin border_radiusSH($br_tl_value, $br_tr_value, $br_bl_value, $br_br_value) {
  @each $prefix in $set_prefix {
      #{$prefix}border-radius: $br_tl_value $br_tr_value $br_bl_value $br_br_value;
  }
}

// 例：@include css3(transition, 0.5s);
@mixin css3($property, $value) {
 @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
      #{$prefix}#{$property}: $value;
 }
}

// 例：@include opacity(0.5);
@mixin opacity($opacity) {
     opacity: $opacity;
     $opacityIE: $opacity * 100;
     filter: alpha(opacity=$opacityIE);
     -moz-opacity: $opacity;
}
// 例：@include font-size(12px);
@mixin font-size($size) {
 font-size:$size;
 font-size: ($size / 10px) * 1rem;
 }

@mixin wide {
  max-width: none;
  width: 100%;
  height: auto;
}
@mixin linkB {
  color:#323232;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
  transition: color 0.5s;
  &:hover {
    color:#900;
  }
 }

// 例：@include opacity(0.5);
@mixin icoset {
 font-family:icoset;
  position: absolute;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// clearFix （MacIE、NN除外版）
@mixin cf {
  @if $support-ie6 or $support-ie7 {
    *zoom: 1;
  }
  &:after {
    content:"";
    display:block;
    clear:both;
  }
}

//%inline-flex { @include flexbox; }
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

//----------------------------------
//%inline-flex { @include inline-flex; }
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}



//----------------------------------------------------------------------

// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property
@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
  // Shorter version:
//  @mixin flex-dir($args...) { @include flex-direction($args...); }
//

//.flex-direction-A { @include flex-direction; }
//.flex-dir-A { @include flex-dir; }

///* row */
//.flex-direction-B { @include flex-direction(row); }
//.flex-dir-B { @include flex-dir(row); }

///* row-reverse */
//.flex-direction-C { @include flex-direction(row-reverse); }
//.flex-dir-C { @include flex-dir(row-reverse); }

///* column */
//.flex-direction-D { @include flex-direction(column); }
//.flex-dir-D { @include flex-dir(column); }

///* column-reverse */
//.flex-direction-E { @include flex-direction(column-reverse); }
//.flex-dir-E { @include flex-dir(column-reverse); }

//----------------------------------------------------------------------

// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in.
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}
//.flex-wrap-A { @include flex-wrap; }

///* nowrap */
//.flex-wrap-B { @include flex-wrap(nowrap); }

///* nowrap */
//.flex-wrap-C { @include flex-wrap(wrap); }

///* nowrap */
//.flex-wrap-D { @include flex-wrap(wrap-reverse); }
//----------------------------------------------------------------------

// Flexbox Flow (shorthand)
//
// The 'flex-flow' property is a shorthand for setting the 'flex-direction'
// and 'flex-wrap' properties, which together define the flex container's
// main and cross axes.
//
// Values: <flex-direction> | <flex-wrap>
// Default: row nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-flow-property

@mixin flex-flow($values: (row nowrap)) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}
//.flex-flow-A { @include flex-flow; }
///* row nowrap */
//.flex-flow-B { @include flex-flow(row nowrap); }
///* column-reverse wrap */
//.flex-flow-C { @include flex-flow(column-reverse wrap); }


//----------------------------------------------------------------------

// Flexbox Order
//
// The 'order' property controls the order in which flex items appear within
// their flex container, by assigning them to ordinal groups.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#order-property

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}
//.order-A { @include order; }
///* -1 */
//.order-B { @include order(-1); }
///* 5 */
//.order-C { @include order(5); }

//----------------------------------------------------------------------

// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}
//.flex-grow-A { @include flex-grow; }
///* 3 */
//.flex-grow-B { @include flex-grow(3); }
//----------------------------------------------------------------------

// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}
//.flex-shrink-A { @include flex-shrink; }
///* 2 */
//.flex-shrink-B { @include flex-shrink(2); }


//----------------------------------------------------------------------

// Flexbox Basis
//
// The 'flex-basis' property sets the flex basis. Negative lengths are invalid.
//
// Values: Like "width"
// Default: auto
//
// http://www.w3.org/TR/css3-flexbox/#flex-basis-property

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}
//.flex-basis-A { @include flex-basis; }
//.flex-basis-B { @include flex-basis(18rem); }

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property

@mixin flex($fg: 1, $fs: null, $fb: null) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}
//.flex-A { @include flex; }
///* 1, 6, 20% */
//.flex-B { @include flex(1, 6, 20%); }
///* 1, 20%, null */
//.flex-C { @include flex(1, 20%); }
///* 1, null, null */
//.flex-D { @include flex(1); }


//----------------------------------------------------------------------

// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}
  // Shorter version:
 // @mixin flex-just($args...) { @include justify-content($args...); }
//.justify-content-A { @include justify-content; }
//.flex-just-A { @include flex-just; }
///* flex-start */
//.justify-content-B { @include justify-content(flex-start); }
//.flex-just-B { @include flex-just(flex-start); }
///* flex-end */
//.justify-content-C { @include justify-content(flex-end); }
//.flex-just-C { @include flex-just(flex-end); }
///* flex-end */
//.justify-content-D { @include justify-content(center); }
//.flex-just-D { @include flex-just(center); }
///* space-between */
//.justify-content-E { @include justify-content(space-between); }
//.flex-just-E { @include flex-just(space-between); }
///* space-around */
//.justify-content-E { @include justify-content(space-around); }
//.flex-just-E { @include flex-just(space-around); }

//----------------------------------------------------------------------

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}
//.align-items-A { @include align-items; }
//
///* flex-start */
//.align-items-C { @include align-items(flex-start); }
//
///* flex-end */
//.align-items-D { @include align-items(flex-end); }
//
///* center */
//.align-items-E { @include align-items(center); }
//
///* baseline */
//.align-items-F { @include align-items(baseline); }
//
///* stretch */
//.align-items-G { @include align-items(stretch); }

//----------------------------------

// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}
//.align-self-A { @include align-self; }
//
///* auto */
//.align-self-B { @include align-self(auto); }
//
///* flex-start */
//.align-self-C { @include align-self(flex-start); }
//
///* flex-end */
//.align-self-D { @include align-self(flex-end); }
//
///* center */
//.align-self-E { @include align-self(center); }
//
///* baseline */
//.align-self-F { @include align-self(baseline); }
//
///* stretch */
//.align-self-G { @include align-self(stretch); }

//----------------------------------------------------------------------

// Flexbox Align Content
//
// The 'align-content' property aligns a flex container's lines within the
// flex container when there is extra space in the cross-axis, similar to
// how 'justify-content' aligns individual items within the main-axis. Note,
// this property has no effect when the flexbox has only a single line.
//
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-content-property

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}
//.align-content-A { @include align-content; }
//
///* flex-start */
//.align-content-B { @include align-content(flex-start); }
//
///* flex-end */
//.align-content-C { @include align-content(flex-end); }
//
///* center */
//.align-content-D { @include align-content(center); }
//
///* space-between */
//.align-content-E { @include align-content(space-between); }
//
///* space-around */
//.align-content-F { @include align-content(space-around); }
//
///* stretch */
//.align-content-G { @include align-content(stretch); }


// 画像スペース対策
@mixin fzero {
  font-size:0;
  line-height:0;
}

// ぶら下がりインデント
@mixin indent {
  padding-left: 1em;
  text-indent: -1em;
}


// position

@mixin position-r($width,$height) {
  position:relative;
  width: $width + px;
  height: $height + px;
}
@mixin position-a($angle1,$num1,$angle2,$num2,$width,$height) {
  position: absolute;
  #{$angle1}: $num1 + px;
  #{$angle2}: $num2 + px;
  width: $width + px;
  height: $height + px;
}
// @include position("top",10,"left",10,100,400);
// 位置（上下）、位置px、位置（左右）、位置px、width、height


// min-height
@mixin min-height($px) {
  min-height: $px + px;
  @if $support-ie6 or $support-ie7 {
  height: auto!important;
  height: $px + px;
  }
}

// inline-block
@mixin inline-block {
  display: inline-block;
  @if $support-ie6 or $support-ie7 {
  *display: inline;
  *zoom: 1;
  }
}

// CSS Sprite horizon
@mixin sprite($accum, $width, $height ,$sp, $stay, $type) {
  width: $width;
  background-position: $accum 0;
  &:hover {
    background-position: $accum (-$height);
  }
  @if $stay == "1" {
    &.stay,
    &.stay:hover {
      @if $type == "2col" {
      background-position: $accum (-$height);
      }
      @if $type == "3col" {
      background-position: $accum (-$height)*2; // hover時と高さが違う場合
      }
      @if $support-ie6 or $support-ie7 {*zoom: 1;}
    }
  }
}

// CSS Sprite  vertical
@mixin sprite-v($accum, $width, $height, $sp, $stay, $type) {
  height: $height;
  background-position: 0 $accum;
  &:hover {
    background-position: (-$width) $accum;
  }
  @if $stay == "1" {
    &.stay,
    &.stay:hover {

      @if $type == "2col" {
      background-position: (-$width) $accum;
      }
      @if $type == "3col" {
      background-position: (-$width)*2 $accum; // hover時と高さが違う場合
      }
      @if $support-ie6 or $support-ie7 {*zoom: 1;}
    }
  }
}


// CSS Sprite 画像+hoverタイプ


@mixin sprite-img($img-over, $img-stay, $width, $height, $stay) {
  background: url($img-over) no-repeat;
    @if $stay =="1" {
      &.stay      {background: url($img-stay) no-repeat;visibility: visible; }
      &.stay img  {visibility: hidden;}
      &.stay:hover{
      }
      &.stay:hover img {
      background: url($img-stay) no-repeat;
      visibility: hidden;
      }
    }
}


// メディアクエリ
$breakpoint-pc2: 1450px;
$breakpoint-pc: 1025px;
$breakpoint-tablet: 768px;

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin tablet() {
  .isTablet & {
    @content;
  }
}