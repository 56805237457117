/* ===============================================================================
* service styles
 * =============================================================================== */
.page-service {
  .container,
  .contents-cover {
    background: #FFF;
  }

  .subttl + .lead {
    text-align: center;
    padding: 0 30px;
    margin: 50px 0;
    @include font-size(16px);
    line-height: 2;
  }
  
  .service-wrap {
    .wrap {
      z-index: 1;
      position: relative;
    }
  }
  
  @include min-screen($breakpoint-tablet) {
    .subttl + .lead {
      padding: 0 50px;
    }

    .contents-cover {
      padding-top: 80px;
    }
    
    .service-wrap {
      .wrap {
        z-index: initial;
        position: static;
      }
    }
  }
  
  
  .service-wrap {
    padding: 0;
    z-index: 1;
    position: relative;
  }
  
  .service-content {
    position: relative;
    margin-bottom: 50px;
  }
  
  .service-bg-img {
    position: relative;
    width: 100%;
    overflow: hidden;
    
    &:before {
      content: "";
      display: block;
      padding-top: 40%;
    }
    img {
      position: absolute;
      top: -30%;
      left: 0;
      bottom: 0;
      right: 0;
      max-width: none;
    }
  }
  
  .service-content-outer {
    position: relative;
  }

  .service-content-inner {
    width: 100%;
    position: relative;
    z-index: 2;
  }
  .service-title {
    position: relative;
    @include font-size(45px);
    width: 60%;
  }

  .service-lead {
    position: relative;
    width: 60%;
  }

  .service-list {
    position: absolute;
    top: 30px;
    right: 0;
    @include font-size(12px);
    color: #685a35;
    border-left: 1px solid #685a35;
    padding: 3px 15px;
    width: 35%;
  }
  
  .bg-box {
    position: relative;
    z-index: 0;
    width: 100%;

    &.bg-box1,
    &.bg-box2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.025);
      }
    }
    &.bg-box2 {
      &:after {
        //border: 1px solid  #685a35;
        //background: none;
      }
    }
  }
  
  .service-web {
    .bg-box {
      &.bg-box1 {
        top: 10%;
        left: 10%;
      }
      &.bg-box2 {
        height: 80%;
        top: 0;
        left: 0;
      }
    }
  }
  .service-graphic {
    .bg-box {
      &.bg-box1 {
        top: -10%;
        left: -10%;
      }
      &.bg-box2 {
        top: 10%;
        left: 10%;
      }
    }
  }
  .service-video {
    .bg-box {
      &.bg-box1 {
        top: -10%;
        left: 0;
      }
      &.bg-box2 {
        height: 80%;
        top: 20%;
        left: 10%;
      }
    }
  }
  .service-etc {
    .bg-box {
      &.bg-box1 {
        top: -10%;
        left: -10%;
      }
      &.bg-box2 {
        top: 10%;
        left: 10%;
      }
    }
  }
  
  @include min-screen($breakpoint-tablet) {
    .service-wrap {
      padding:180px 0 60px 0;
      background: url("#{$path-img}service/images/bg_img.jpg");
    }

    .service-content {
      clear: both;
      position: relative;
      margin-bottom: 150px;

      &:after {
        content: "";
        clear: both;
        display: block;
      }
    }

    .service-bg-img {
      position: absolute;
      width: 50%;
      z-index: 2;
      overflow: visible;
      background: rgba(0,0,0, 0.2);
      
      &:before {
        display: none;
      }

      img {
        position: static;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        max-width: initial;
      }
    }

    .service-content-outer {
      width: 60%;
      height: 600px;
      // border: 1px solid #685a35;
      z-index: 1;
      position: relative;
      

      &.border,
      &.background {
        &:before,
        &:after {
          box-sizing: border-box;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 1;
        }

        &:before,
        &:after {
          border: 1px solid transparent;
          width: 0;
          height: 0;
        }
        &:before {
          top: 0;
          left: 0;
        }
        &:after {
          bottom: 0;
          right: 0;
        }
      }
    }
    .service-content {
      &.anim-hidden {
        visibility: hidden;
        
        .service-content-inner {
          opacity: 0;
        }
      }
      &.anim {
        visibility: visible;
        
        .service-content-outer {
          &.border {
            &:before,
            &:after {
              width: 100%;
              height: 100%;
            }

            &:before {
              border-top-color: #685a35;
              border-right-color: #685a35;
              transition:
                width 0.25s ease-out,
                height 0.25s ease-out 0.25s;
            }

            &:after {
              border-bottom-color: #685a35;
              border-left-color: #685a35;
              transition:
                border-color 0s ease-out 0.5s,
                width 0.25s ease-out 0.5s,
                height 1s cubic-bezier(0.23, 1, 0.32, 1) 0.75s;
            }
          }
          
          &.background {
            &:before,
            &:after {
              width: 100%;
              height: 100%;
              opacity: 0;
            }

            &:before {
              border-top-color: #685a35;
              border-right-color: #685a35;
              transition:
                width 0.25s ease-out,
                height 0.25s ease-out 0.25s,
                opacity 0.5s 1.9s;
            }

            &:after {
              border-bottom-color: #685a35;
              border-left-color: #685a35;
              transition:
                border-color 0s ease-out 0.5s,
                width 0.25s ease-out 0.5s,
                height 1s cubic-bezier(0.23, 1, 0.32, 1) 0.75s,
                opacity 0.5s 1.9s;
            }
          }
        }
        .service-content-inner {
          opacity: 1;
          transition: opacity 0.5s 0.5s;
        }
      }
    }

    .service-content-inner {

    }

    .service-title {
      position: relative;
      @include font-size(72px);
      width: auto;
    }

    .service-lead {
      position: relative;
      width: 100%;
    }

    .service-list {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
    }

    .bg-box {
      width: 100%;

      &.bg-box1,
      &.bg-box2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 600px;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.025);
          border: none;
        }
      }

      &.bg-box2 {
        width: 50%;
        border: none;
      }
    }

    .service-web {
      .service-content-outer {
        float: left;
      }

      .service-bg-img {
        top: -100px;
        right: 0;
      }

      .service-content-inner {
        margin: 120px 60px 0;
      }

      .service-title {
        position: absolute;
        top: 0;
        right: 30%;
      }

      .service-lead {
        position: absolute;
        top: 140px;
        left: 0;
        width: 40%;
      }

      .service-list {
        position: absolute;
        top: 130px;
        left: 50%;
        width: 25%;
      }

      .bg-box {
        &.bg-box1 {
          top: 230px;
          left: auto;
          height: 340px;
        }

        &.bg-box2 {
          top: -70px;
          left: 30%;
          width: 50%;
          height: 100%;
        }
      }
    }

    .service-graphic {
      margin-bottom: 200px;

      .service-content-outer {
        float: right;
        width: 70%;
      }

      .service-bg-img {
        top: -100px;
        left: 0;
        height: 100%;
        width: 40%;

        img {
          position: absolute;
          right: 0px;
        }
      }

      .service-content-inner {
        margin: 120px 0 0 26%;
      }

      .service-title {}

      .service-lead {
        position: absolute;
        top: 160px;
        left: 30%;
        width: 35%;
      }

      .service-list {}

      .bg-box {
        &.bg-box1 {
          top: -20px;
          left: auto;
          height: 640px;
        }

        &.bg-box2 {
          top: 100px;
          left: 15%;
          width: 70%;
          height: 100%;
        }
      }
    }

    .service-video {
      margin-bottom: 0;

      .service-content-outer {
        float: left;
        margin-bottom: -60px;
        background: rgba(0, 0, 0, 0);
        border: none;
        height: 540px;
      }

      .service-bg-img {
        top: -100px;
        right: 0;
      }

      .service-content-inner {
        margin: 120px 60px 0;
      }

      .service-title {
        width: 60%;
      }

      .service-lead {
        width: 40%;
      }

      .service-list {
        position: absolute;
        top: 120px;
        left: 40%;
        width: 30%;
      }
      .bg-box {
        display: none;
      }
      
      &.anim {
        .service-content-outer {
          background: rgba(0, 0, 0, 0.05);
          transition: background 0.5s 1.9s;
        }
      }
    }

    .service-etc {
      .service-content-outer {
        float: right;
        width: 65%;
      }

      .service-bg-img {
        top: 60px;
        left: 0;
        height: 100%;

        img {
          position: absolute;
          right: 0;
        }
      }

      .service-content-inner {
        margin: 240px 0 0 50%;
      }

      .service-title {}

      .service-lead {}
      
      .bg-box {
        display: none;
      }
    }
  }
}